import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import commonActions from '../common/actions'
import api from "../../api";

function* loadFromServerTask({payload}) {
    try {
        const {uuid: taskUuid} = payload

        if (!taskUuid) return;


        let results = yield api.getTask({taskUuid})

        const {task} = results

        if (task) {
            yield put(actions.loadFromServerTaskSuccess({task: task}))
        }

    } catch (e) {
        yield put(actions.loadFromServerTaskError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerTasks({payload}) {

    try {

        const storeData = {}

        const {pageToken} = payload

        if (pageToken) {

            let results = yield api.getTaskListPage({pageToken})
            const {tasks} = results

            if (tasks) {
                storeData['tasks'] = tasks;
            }

        } else {
            storeData['tasks'] = [];
        }

        yield put(actions.loadFromServerTasksSuccess(storeData))

    } catch (e) {
        yield put(actions.loadFromServerTasksError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerTasksPages({payload}) {

    try {

        const {filter, itemsPerPage} = payload

        const data = {itemsPerPage}

        if (filter) {
            data.filter = filter
        }

        let results = yield api.getTaskListPages(data)
        const {pageTokens} = results

        const storeData = {}
        storeData['Pages'] = pageTokens || [];

        yield put(actions.loadFromServerTasksPagesSuccess(storeData))
        yield put(actions.loadFromServerTasks({pageToken: pageTokens[0]}))

    } catch (e) {
        yield put(actions.loadFromServerTasksPagesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* storeIntoServer({payload}) {

    const {data, actionName, onSuccessMessage, callback} = payload;
    const {uuid} = data

    // //фейк
    // data.questions = []

    try {
        let results = null
        switch (actionName) {
            case 'delete':
                results = yield api.removeTask({taskUuid: uuid})
                break;
            case 'update':
                results = yield api.updateTask({taskUuid: uuid, taskData: data})
                break;
            case 'add':
                results = yield api.addTask({taskData: data})
                break;
            default:
                return;
        }

        if (results) {
            yield put(commonActions.showMessage('success', onSuccessMessage))
            callback && callback()
        }


    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_TASKS_PAGES, loadFromServerTasksPages),
        takeEvery(actions.LOAD_FROM_SERVER_TASKS, loadFromServerTasks),
        takeEvery(actions.LOAD_FROM_SERVER_TASK, loadFromServerTask),
        takeEvery(actions.SAVE_INTO_SERVER_TASK, storeIntoServer),
    ]);
}
