import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {routerReducer, routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from '../redux/reducers';
import rootSaga from '../redux/sagas';

class ProviderService {

    static createProviderStore(initialState = {}, history = null, isServerSide = false) {

        const composeEnhancers =
            typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
                })
                : compose;

        const sagaMiddleware = createSagaMiddleware();
        const routeMiddleware = routerMiddleware(history);
        const middlewares = [thunk, sagaMiddleware, routeMiddleware];

        const store = createStore(
            combineReducers({
                ...reducers,
                router: routerReducer,
            }),
            composeEnhancers(applyMiddleware(...middlewares))
        );

        sagaMiddleware.run(rootSaga);

        ProviderService._setupHotReloading(store);

        return store;
    }

    static _setupHotReloading(store) {
        if (module.hot) {
            module.hot.accept('../redux/reducers', () => {
                const nextReducer = require('../redux/reducers').default; // eslint-disable-line global-require

                store.replaceReducer(nextReducer);
            });
        }
    }

}

export default ProviderService;
