const optionsLessons = [
  {
    key: 'getlesson',
		label: 'sidebar.get_lesson',
  },
  {
    key: 'homework',
    label: 'sidebar.homework',
	},
	{
    key: 'onlinelesson',
    label: 'sidebar.online_lessons',
  },
];
export default optionsLessons;
