const DOCUMENT = 'REVIEW_LESSON_'

const actions = {

    LOAD_FROM_SERVER_LESSON_INFO: DOCUMENT + 'LOAD_FROM_SERVER_LESSON_INFO',
    LOAD_FROM_SERVER_LESSON_INFO_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_LESSON_INFO_SUCCESS',
    LOAD_FROM_SERVER_LESSON_INFO_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_LESSON_INFO_ERROR',

    loadFromLessonInfo: data => ({
        type: actions.LOAD_FROM_SERVER_LESSON_INFO,
        payload: data,
    }),

    loadFromLessonInfoSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_LESSON_INFO_SUCCESS,
        payload: data,
    }),

    loadFromLessonInfoError: error => ({
        type: actions.LOAD_FROM_SERVER_LESSON_INFO_ERROR,
        payload: {error},
    }),
}
export default actions
