import styled from 'styled-components';
import { palette } from 'styled-theme';
import { siteConfig } from '../../settings';
import { transition, borderRadius } from '../../settings/style-util';
import WithDirection from '../../settings/withDirection';

const SidebarWrapper = styled.div`
		padding-top: 60px;
		padding-bottom: 30px;
		position: relative;

	.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
			border: 0;
	}

	.sidebar-scrollable-section {
		overflow-y: auto;
		height: 100%;
		padding-bottom: 20px;
	}

	.mobile-trigger-btn-container {
		position: absolute;
    top: 10px;
    font-size: 28px;
    left: 10px;
		z-index: 10;
		.triggerBtn {
			width: 28px;
			font-size: 25px;
			display: -webkit-inline-flex;
			display: -ms-inline-flex;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			border: 0;
			outline: 0;
			cursor: pointer;
			touch-action: manipulation;

			/* &:before {
				content: '\f20e';
				font-family: 'Ionicons';
				font-size: 26px;
				color: inherit;
				line-height: 0;
				color: #fff;
				position: absolute;
			} */
		}
	}

  .isomorphicSidebar {
    z-index: 1000;
    width: 300px;
		flex: 0 0 300px;
		padding-top: 60px;
		padding-bottom: 30px;
		height: 100%;
		background-image: linear-gradient(-36deg, ${palette('primary', 0)} 0%, ${palette('primary', 0)} 100%);
		border: 0;
		position: relative;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		box-shadow: 10px 11.1px 7.6px 1.4px rgba(0, 131, 253, 0.14);

    /* .scrollarea {
			height: calc(100vh - 250px);
		} */

		.sidebar-trigger-btn-container {
			width: 100%;
			background-color: transparent;
			padding: 15px;
			position: absolute;
			top: 0;
			left: 0;
			text-align: right;
		}
		
		.triggerBtn {
			width: 28px;
			font-size: 25px;
			display: -webkit-inline-flex;
			display: -ms-inline-flex;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			border: 0;
			outline: 0;
			cursor: pointer;
			touch-action: manipulation;

			/* &:before {
				content: '\f20e';
				font-family: 'Ionicons';
				font-size: 26px;
				color: inherit;
				line-height: 0;
				color: #fff;
				position: absolute;
			} */
		}

		.sidebar-menu-title {
			color: #522342;
			font-size: 24px;
			margin-bottom: 0;
			letter-spacing: 0.3px;
			padding-left: 24px;
		}

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 575px) {
        width: 56;
        min-width: 56 !important;
        max-width: 56 !important;
        flex: 0 0 56 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      background: rgba(0, 0, 0, 0.3);
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 21px;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: ${palette('grayscale', 6)};
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

		.sidebar-menu-section {
			&:not(:last-child) {
				margin-bottom: 40px;
			}
		}

    .isoDashboardMenu {
      padding-top: 5px;
      padding-bottom: 10px;
			background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
				margin: 0;
				
				&:after {
					border-right: 3px solid #fff;
				}
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        i {
          font-size: 19px;
          color: inherit;
          margin: ${props =>
            props['data-rtl'] === 'rtl' ? '0 0 0 30px' : '0 30px 0 0'};
          width: 18px;
          ${transition()};
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 16px;
        color: #fff;
        font-weight: 400;
        ${transition()};
      }

      .ant-menu-item-selected {
				background-color: rgba(255, 255, 255, 0.9) !important;
				
				&:after {
					border-right: 3px solid #fff;
				}
				
        .anticon {
          color: #522342;
        }

        i {
          color: #522342;
        }

        .nav-text {
          color: #522342;
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            color: #522342;
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette('secondary', 5)};
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 24px;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${'' /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */};
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${props =>
            props['data-rtl'] === 'rtl' ? '0px !important' : '74px !important'};
          padding-right: ${props =>
            props['data-rtl'] === 'rtl' ? '74px !important' : '0px !important'};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
			}
			
			.sidebar-menu-section {
				display: none;
			}

      .ant-menu-submenu-inline >  {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
	}
	
@media only screen and (max-width: 575px) {

	.isomorphicSidebar {
		padding-top: 30px;
		height: calc(100% - 100px);
		position: absolute;

		.sidebar-menu-title {
			font-size: 18px;
			padding-left: 10px;
		}
		.isoDashboardMenu {
			.ant-menu-item {
				padding: 0 10px!important;
			}
			.nav-text {
				font-size: 13px;
			}
		}
		/* .sidebar-trigger-btn-container {
			display: none;
		} */
	}
}
`;

export default WithDirection(SidebarWrapper);
