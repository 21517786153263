import actions from './actions'

const initState = {
    track: [],
    answers: [],
    isLoading: false,
    redirectToReviewForm: false,
}

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {
        case actions.JOIN_IN_ROOM:
            return {
                ...state,
                accessToken: null,
                isLoading: true
            }
        case actions.JOIN_IN_ROOM_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            }
        case actions.JOIN_IN_ROOM_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            }
        case actions.LEAVE_ROOM:
            return initState
        case actions.ADD_TRACK:
            return {
                ...state,
                track: state.track.concat([payload])
            }
        case actions.REMOVE_TRACK:
            return {
                ...state,
                track: state.track.filter(track => track.sid !== payload.sid)
            }

        case actions.LOAD_FROM_SERVER_TASKS:
            return {
                ...state,
                tasks: null,
                isLoading: true
            }

        case actions.LOAD_FROM_SERVER_TASKS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            }

        case actions.LOAD_FROM_SERVER_TASKS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            }

        case actions.SHOW_TASK:
            return {
                ...state,
                ...payload,
            }

        case actions.RECIVED_ANSWER_FROM_STUDENT:
            const {answer} = payload;
            const {taskUuid, index, value} = answer;
            const {answers} = state
            if (!answers[taskUuid]) {
                answers[taskUuid] = [{
                    index, value
                }]
            } else {
                const i = answers[taskUuid].findIndex(el => el.index === index);
                if (i === -1) {
                    answers[taskUuid].push({
                        index, value
                    })
                } else {
                    answers[taskUuid].splice(i, 1, {
                        index, value
                    })
                }
            }

            return {
                ...state,
                ...{answers: answers},
                ...{lastMessageAt: new Date()},
            }

        case actions.REDIRECT_TO_REVIEW_FORM:
            return {
                ...state,
                redirectToReviewForm: true,
            }
        default:
            return state
    }
}
