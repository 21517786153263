const DOCUMENT = 'STUDENT_ONLINE_LESSON_'

const actions = {
    LOAD_FROM_SERVER_ONLINE_LESSONS: DOCUMENT + 'LOAD_FROM_SERVER_ONLINE_LESSONS',
    LOAD_FROM_SERVER_ONLINE_LESSONS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_ONLINE_LESSONS_SUCCESS',
		LOAD_FROM_SERVER_ONLINE_LESSONS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_ONLINE_LESSONS_ERROR',
		
		LOAD_FROM_SERVER_HOMETASKS: DOCUMENT + 'LOAD_FROM_SERVER_HOMETASKS',
    LOAD_FROM_SERVER_HOMETASKS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_HOMETASKS_SUCCESS',
		LOAD_FROM_SERVER_HOMETASKS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_HOMETASKS_ERROR',
		
		LOAD_FROM_SERVER_HOMETASK: DOCUMENT + 'LOAD_FROM_SERVER_HOMETASK',
    LOAD_FROM_SERVER_HOMETASK_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_HOMETASK_SUCCESS',
    LOAD_FROM_SERVER_HOMETASK_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_HOMETASK_ERROR',

    loadFromServerOnlineLessons: () => ({
        type: actions.LOAD_FROM_SERVER_ONLINE_LESSONS,
    }),

    loadFromServerOnlineLessonsSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_ONLINE_LESSONS_SUCCESS,
        payload: data,
    }),

    loadFromServerOnlineLessonsError: error => ({
        type: actions.LOAD_FROM_SERVER_HOMETASKS_ERROR,
        payload: {error},
		}),
		
		loadFromServerHomeTasks: () => ({
			type: actions.LOAD_FROM_SERVER_HOMETASKS,
		}),

		loadFromServerHomeTasksSuccess: data => ({
				type: actions.LOAD_FROM_SERVER_HOMETASKS_SUCCESS,
				payload: data,
		}),

		loadFromServerHomeTasksError: error => ({
				type: actions.LOAD_FROM_SERVER_HOMETASK_ERROR,
				payload: {error},
		}),

		loadFromServerHomeTask: data => ({
			type: actions.LOAD_FROM_SERVER_HOMETASK,
			payload: data,
		}),

		loadFromServerHomeTaskSuccess: data => ({
				type: actions.LOAD_FROM_SERVER_HOMETASK_SUCCESS,
				payload: data,
		}),

		loadFromServerHomeTaskError: error => ({
				type: actions.LOAD_FROM_SERVER_HOMETASK_ERROR,
				payload: {error},
		}),
}
export default actions
