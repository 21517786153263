import { all, takeEvery, put } from 'redux-saga/effects'
import actions from './actions'
import api from '../../api/'
import commonActions from '../common/actions'

function* loadFromServerPaymentDetails({payload}) {
    const { paymentUUID } = payload

    if (paymentUUID) {
          try {
              const results = yield api.studentPaymentDetails({paymentUUID})

              if (results) {
                const { payment: {amount: { value }, status }} = results;
                yield put(commonActions.showMessage('success', `Ваш платеж на сумму ${value} руб. ${status.toString() === 'succeeded' ? 'выполнен' : 'ожидает списания'}`))
              }

          } catch (e) {
              yield put(commonActions.showMessage('error', e.toString()))
          }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_PAYMENT_DETAILS, loadFromServerPaymentDetails),
    ])
}
