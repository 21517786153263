const optionsCourse = [
    {
        key: 'courses',
        label: 'sidebar.courses',
    },
    {
        key: 'buycourse',
        label: 'sidebar.buy_course',
    },
    {
        key: 'buy-subscription',
        label: 'sidebar.buy_subscription',
    },
    {
        key: 'gettested',
        label: 'sidebar.get_tested',
    },
];
export default optionsCourse;
