import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import commonActions from '../common/actions';
import api from "../../api";

function* loadFromServerSubscription({payload = {}}) {
    try {
      const result = yield api.getSubscriptionInfo();

			const { subscription } = result;

      if (subscription) {
        yield put(actions.loadFromServerSubscriptionSuccess({ subscription }));
      }

    } catch (e) {
      yield put(actions.loadFromServerSubscriptionError({...e}));
    }
}

function* purchaseSubscription({payload = {}}) {
    try {
      const { paymentType } = payload;
      const result = yield api.purchaseSubscription({ paymentType });

      if (result.ok) {
        yield put(commonActions.showMessage('success', 'Purcahsed!'))
        yield put(actions.purchaseSubscriptionSuccess({}));
      } else {
        yield put(commonActions.showMessage('error', result.message))
        throw Error(result.message);
      }
    } catch (e) {
      yield put(actions.purchaseSubscriptionError({...e}));
    }
}

export default function* rootSaga() {
  yield all([
		takeEvery(actions.LOAD_FROM_SERVER_SUBSCRIPTION, loadFromServerSubscription),
    takeEvery(actions.PURCHASE_SUBSCRIPTION, purchaseSubscription),
  ]);
}
