import actions from './actions';

const initState = {
    component: null
};

export default function reducer(
    state = initState,
    {type, payload}
) {

    switch (type) {
        case actions.SHOW_COMPONENT:
            return {
                ...state,
                ...payload,
            };
        case actions.HIDE_COMPONENT:
            return {
                ...initState
            };
        default:
            return state;
    }
}
