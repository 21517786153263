import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {
        case actions.LOAD_FROM_SERVER_EXAM:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_EXAM_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_EXAM_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };

        case actions.START_EXAM:
            return {
                ...state,
                isLoading: true
            };
        case actions.START_EXAM_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.START_EXAM_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.SAVE_EXAM_ANSWERS:
            return {
                ...state,
                isLoading: true
            };

        default:
            return state;
    }
}
