import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import asyncComponent from '../../helpers/AsyncFunc'
// import customRoutes from '../../customApp/router';


const routes = [
    {
        path: '',
        component: asyncComponent(() => import('../../customApp/containers/blankPage')),
    },
    {
        path: 'courses',
        component: asyncComponent(() => import('../Courses/studentCourses')),
    },
    {
        path: 'courses/:uuid',
        component: asyncComponent(() => import('../Courses/studentCourses')),
    },
    {
        path: 'courseslist',
        component: asyncComponent(() => import('../Courses/adminCourses')),
    },
    {
        path: 'courseslist/:uuid/edit',
        component: asyncComponent(() => import('../Courses/editCourse')),
    },
    {
        path: 'taskslist/create',
        component: asyncComponent(() => import('../Tasks/createTask')),
		},
		{
        path: 'shedule',
        component: asyncComponent(() => import('../TeacherShedule')),
		},
    {
        path: 'taskslist/:uuid/edit',
        component: asyncComponent(() => import('../Tasks/editTask')),
    },
    {
        path: 'courseslist/create',
        component: asyncComponent(() => import('../Courses/createCourse')),
    },
    {
        path: 'getlesson',
        component: asyncComponent(() => import('../Getlesson')),
    },
    {
        path: 'get-online-lesson/:courseUuid/:teacherUuid/',
        component: asyncComponent(() => import('../Getlesson')),
    },
    {
        path: 'get-online-lesson/:courseUuid/',
        component: asyncComponent(() => import('../Getlesson')),
    },
    {
        path: 'courses/:courseId/:lessonId',
        component: asyncComponent(() => import('../Lessons/studentLessons')),
    },
    {
        path: 'buycourse',
        component: asyncComponent(() => import('../Courses/buyCourse')),
    },
    {
        path: 'buy-subscription',
        component: asyncComponent(() => import('../Courses/buySubscription')),
    },
    {
        path: 'homework',
        component: asyncComponent(() => import('../Homework')),
		},
		{
        path: 'homework/:homeTaskUUID',
        component: asyncComponent(() => import('../Homework/homeworkTasks')),
		},
  	{
        path: 'pupils',
        component: asyncComponent(() => import('../MyPupils')),
		},
		{
        path: 'pupils/:uuid',
        component: asyncComponent(() => import('../MyPupils/viewStudentProfile')),
		},
		{
        path: 'teachers/:uuid',
        component: asyncComponent(() => import('../Teachers/viewTeacherProfile')),
		},
    {
        path: 'tasksbank',
        // component: asyncComponent(() => import('../TasksBank')),
        component: asyncComponent(() => import('../Tasks/tasksList')),
    },
    {
        path: 'tasksbank/create',
        component: asyncComponent(() => import('../Tasks/createTask')),
    },
    {
        path: 'tasksbank/:uuid/edit',
        component: asyncComponent(() => import('../Tasks/editTask')),
    },
    {
        path: 'teachers',
        component: asyncComponent(() => import('../Teachers')),
    },
    {
        path: 'taskslist',
        component: asyncComponent(() => import('../Tasks/tasksList')),
    },
    {
        path: 'entrancetest',
        component: asyncComponent(() => import('../EntranceTest')),
    },
    {
        path: 'settings',
        component: asyncComponent(() => import('../Settings')),
    },
    {
        path: 'tasksview',
        component: asyncComponent(() => import('../Tasks/taskPreview')),
    },
    {
        path: 'lessonslist',
        component: asyncComponent(() => import('../Lessons')),
    },
    {
        path: 'lessonslist/create',
        component: asyncComponent(() => import('../Lessons/createLesson')),
    },
    {
        path: 'lessonslist/:uuid/edit',
        component: asyncComponent(() => import('../Lessons/editLesson')),
    },
    {
        path: 'teacherslist',
        component: asyncComponent(() => import('../Teachers/adminTeachers')),
    },
    {
        path: 'teacherslist/create',
        component: asyncComponent(() => import('../Teachers/createTeacher')),
    },
    {
        path: 'teacherslist/:uuid/edit',
        component: asyncComponent(() => import('../Teachers/editTeacher')),
    },
    {
        path: 'studentslist',
        component: asyncComponent(() => import('../Students/adminStudents')),
    },
    {
        path: 'studentslist/create',
        component: asyncComponent(() => import('../Students/createStudent')),
    },
    {
        path: 'studentslist/:uuid/edit',
        component: asyncComponent(() => import('../Students/editStudent')),
	},
    {
        path: 'exam/create',
        component: asyncComponent(() => import('../Exam/createExam')),
    },
    {
        path: 'exam/:uuid/edit',
        component: asyncComponent(() => import('../Exam/editExam')),
    },
    {
        path: 'gettested',
        component: asyncComponent(() => import('../Exam/gettested')),
    },
    {
        path: 'gettested/:uuid',
        component: asyncComponent(() => import('../Exam/task')),
    },
    {
        path: 'courses/:courseUuid/exam/:examUuid',
        component: asyncComponent(() => import('../Exam/courseGetTested.js')),
    },
        {
        path: 'courses/:courseUuid/exam/:examUuid/task',
        component: asyncComponent(() => import('../Exam/courseGetTestedTask.js')),
    },
    {
        path: 'onlinelesson',
        component: asyncComponent(() => import('../OnlineLesson')),
    },
    {
        path: 'lessonroom/:uuid',
        component: asyncComponent(() => import('../OnlineLesson/lessonroom')),
    },
    {
        path: 'reviewlesson/:uuid',
        component: asyncComponent(() => import('../ReviewLesson/index')),
    },
    {
        path: 'checkout',
        component: asyncComponent(() => import('../Checkout/index')),
    },
    {
        path: 'sertificates',
        component: asyncComponent(() => import('../Sertificates/index')),
    },
    {
        path: 'paymentconfirmation/:uuid',
        component: asyncComponent(() => import('../Checkout/paymentconfirmation')),
    },
    {
        path: 'compositions',
        component: asyncComponent(() => import('../Compositions')),
    },
    {
        path: 'composition/:uuid/review',
        component: asyncComponent(() => import('../Compositions/review')),
    },
	// {
	// 	path: 'profile/:uuid',
	// 	component: asyncComponent(() => import('../Profile')),
	// },
    // {
    //   path: 'editor',
    //   component: asyncComponent(() => import('../Forms/editor/')),
    // },
    // {
    //   path: 'stepperForms',
    //   component: asyncComponent(() => import('../Forms/StepperForms/')),
    // },
    // {
    //   path: 'FormsWithValidation',
    //   component: asyncComponent(() => import('../Forms/FormsWithValidation')),
    // },
    // {
    //   path: 'progress',
    //   component: asyncComponent(() => import('../Forms/Progress')),
    // },
    // {
    //   path: 'button',
    //   component: asyncComponent(() => import('../Forms/Button')),
    // },
    // {
    //   path: 'tab',
    //   component: asyncComponent(() => import('../Forms/Tab')),
    // },
    // {
    //   path: 'autocomplete',
    //   component: asyncComponent(() => import('../Forms/AutoComplete')),
    // },
    // {
    //   path: 'checkbox',
    //   component: asyncComponent(() => import('../Forms/Checkbox')),
    // },
    // {
    //   path: 'radiobox',
    //   component: asyncComponent(() => import('../Forms/Radiobox/')),
    // },
    // {
    //   path: 'selectbox',
    //   component: asyncComponent(() => import('../Forms/Select/')),
    // },
    // {
    //   path: 'transfer',
    //   component: asyncComponent(() => import('../Forms/Transfer/')),
    // },
    // {
    //   path: 'gridLayout',
    //   component: asyncComponent(() => import('../Box/GridLayout')),
    // },
    // {
    //   path: 'notes',
    //   component: asyncComponent(() => import('../Notes')),
    // },
    // {
    //   path: 'todo',
    //   component: asyncComponent(() => import('../Todo')),
    // },
    // {
    //   path: 'articles',
    //   component: asyncComponent(() => import('../FirestoreCRUD/Article')),
    // },
    // {
    //   path: 'investors',
    //   component: asyncComponent(() => import('../FirestoreCRUD/Investor')),
    // },
    // {
    //   path: 'contacts',
    //   component: asyncComponent(() => import('../Contacts')),
    // },
    // {
    //   path: 'alert',
    //   component: asyncComponent(() => import('../Feedback/Alert')),
    // },
    // {
    //   path: 'modal',
    //   component: asyncComponent(() => import('../Feedback/Modal/')),
    // },
    // {
    //   path: 'message',
    //   component: asyncComponent(() => import('../Feedback/Message')),
    // },
    // {
    //   path: 'notification',
    //   component: asyncComponent(() => import('../Feedback/Notification')),
    // },
    // {
    //   path: 'Popconfirm',
    //   component: asyncComponent(() => import('../Feedback/Popconfirm')),
    // },
    // {
    //   path: 'spin',
    //   component: asyncComponent(() => import('../Feedback/Spin')),
    // },
    // {
    //   path: 'shuffle',
    //   component: asyncComponent(() => import('../Shuffle')),
    // },
    // {
    //   path: 'affix',
    //   component: asyncComponent(() => import('../Navigation/affix')),
    // },
    // {
    //   path: 'breadcrumb',
    //   component: asyncComponent(() =>
    //     import('../Uielements/Breadcrumb/breadcrumb')
    //   ),
    // },
    // {
    //   path: 'backToTop',
    //   component: asyncComponent(() => import('../Navigation/backToTop')),
    // },
    // {
    //   path: 'dropdown',
    //   component: asyncComponent(() => import('../Uielements/Dropdown/dropdown')),
    // },
    // {
    //   path: 'op_badge',
    //   component: asyncComponent(() => import('../Uielements/Badge')),
    // },
    // {
    //   path: 'op_card',
    //   component: asyncComponent(() => import('../Uielements/Card')),
    // },
    // {
    //   path: 'op_carousel',
    //   component: asyncComponent(() => import('../Uielements/Carousel')),
    // },
    // {
    //   path: 'op_collapse',
    //   component: asyncComponent(() => import('../Uielements/Collapse')),
    // },
    // {
    //   path: 'op_tooltip',
    //   component: asyncComponent(() => import('../Uielements/Tooltip/')),
    // },
    // {
    //   path: 'rating',
    //   component: asyncComponent(() => import('../Uielements/rating/')),
    // },
    // {
    //   path: 'tree',
    //   component: asyncComponent(() => import('../Uielements/Tree/')),
    // },
    // {
    //   path: 'op_tag',
    //   component: asyncComponent(() => import('../Uielements/Tag')),
    // },
    // {
    //   path: 'op_timeline',
    //   component: asyncComponent(() => import('../Uielements/Timeline')),
    // },
    // {
    //   path: 'op_popover',
    //   component: asyncComponent(() => import('../Uielements/Popover')),
    // },
    // {
    //   path: 'googleChart',
    //   component: asyncComponent(() => import('../Charts/googleChart')),
    // },
    // {
    //   path: 'reecharts',
    //   component: asyncComponent(() => import('../Charts/recharts')),
    // },
    // {
    //   path: 'menu',
    //   component: asyncComponent(() => import('../Navigation/menu')),
    // },
    // {
    //   path: 'ReactChart2',
    //   component: asyncComponent(() => import('../Charts/reactChart2')),
    // },
    // {
    //   path: 'pagination',
    //   component: asyncComponent(() =>
    //     import('../Uielements/Pagination/pagination')
    //   ),
    // },
    // {
    //   path: 'card',
    //   component: asyncComponent(() => import('../Ecommerce/card')),
    // },
    // {
    //   path: 'cart',
    //   component: asyncComponent(() => import('../Ecommerce/cart')),
    // },
    // {
    //   path: 'checkout',
    //   component: asyncComponent(() => import('../Ecommerce/checkout')),
    // },
    // {
    //   path: 'shop',
    //   component: asyncComponent(() =>
    //     import('../Ecommerce/algolia/instantSearch')
    //   ),
    // },
    // {
    //   path: 'reactDates',
    //   component: asyncComponent(() =>
    //     import('../AdvancedUI/ReactDates/reactDates')
    //   ),
    // },
    // {
    //   path: 'codeMirror',
    //   component: asyncComponent(() => import('../AdvancedUI/codeMirror')),
    // },
    // {
    //   path: 'uppy',
    //   component: asyncComponent(() => import('../AdvancedUI/uppy')),
    // },
    // {
    //   path: 'dropzone',
    //   component: asyncComponent(() => import('../AdvancedUI/dropzone')),
    // },
    // {
    //   path: 'youtubeSearch',
    //   component: asyncComponent(() => import('../YoutubeSearch')),
    // },
    // {
    //   path: 'frappeChart',
    //   component: asyncComponent(() => import('../Charts/frappeChart')),
    // },
    // {
    //   path: 'invoice/:invoiceId',
    //   component: asyncComponent(() => import('../Invoice/singleInvoice')),
    // },
    // {
    //   path: 'invoice',
    //   component: asyncComponent(() => import('../Invoice')),
    // },
    // {
    //   path: 'chat',
    //   component: asyncComponent(() => import('../Chat')),
    // },
    // ...customRoutes,
]

class AppRouter extends Component {
    render() {
				const {url, style} = this.props
        return (
            <div style={style}>
                {routes.map(singleRoute => {
										const {path, exact, ...otherProps} = singleRoute
                    return (
                        <Route
                            exact={exact === false ? false : true}
                            key={singleRoute.path}
														path={`${url}/${singleRoute.path}`}
                            {...otherProps}
                        />
                    )
                })}
            </div>
        )
    }
}

export default AppRouter;
