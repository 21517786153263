import { all, takeEvery, put } from 'redux-saga/effects'
import actions from './actions'
import api from '../../api/'
import commonActions from '../common/actions'

function * loadFromServerEntranceTest () {
  try {
    let results = yield api.getEntranceTest()

    const {EntranceTestData} = results

    if (EntranceTestData) {
      yield put(actions.loadFromServerEntranceTestSuccess({entranceTest: EntranceTestData}))
    }

  } catch (e) {
    yield put(actions.loadFromServerEntranceTestError({...e}))
    yield put(commonActions.showMessage('error', e.toString()))
  }
}

function * storeIntoServer ({payload}) {

  const {data, onSuccessMessage, callback} = payload
  const {uuid: entranceTestUuid} = data

  try {
    const results = yield api.updateEntranceTest({entranceTestUuid, entranceTestData: data})

    if (results) {
      yield put(commonActions.showMessage('success', onSuccessMessage))
      callback && callback()
    }

  } catch (e) {
    yield put(commonActions.showMessage('error', e.toString()))
  }
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.LOAD_FROM_SERVER_ENTRANCE_TEST, loadFromServerEntranceTest),
    takeEvery(actions.SAVE_INTO_SERVER_ENTRANCE_TEST, storeIntoServer),
  ])
}
