const DOCUMENT = 'EXAM_';

const actions = {
    LOAD_FROM_SERVER_EXAMS_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_EXAMS_PAGES',
    LOAD_FROM_SERVER_EXAMS_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_EXAMS_PAGES_SUCCESS',
    LOAD_FROM_SERVER_EXAMS_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_EXAMS_PAGES_ERROR',

    LOAD_FROM_SERVER_EXAMS: DOCUMENT + 'LOAD_FROM_SERVER_EXAMS',
    LOAD_FROM_SERVER_EXAMS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_EXAMS_SUCCESS',
    LOAD_FROM_SERVER_EXAMS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_EXAMS_ERROR',

    LOAD_FROM_SERVER_EXAM: DOCUMENT + 'LOAD_FROM_SERVER_EXAM',
    LOAD_FROM_SERVER_EXAM_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_EXAM_SUCCESS',
    LOAD_FROM_SERVER_EXAM_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_EXAM_ERROR',

    SAVE_INTO_SERVER_EXAMS: DOCUMENT + 'SAVE_INTO_SERVER_EXAMS',

    loadFromServerExamsPages: data => ({
        type: actions.LOAD_FROM_SERVER_EXAMS_PAGES,
        payload: data
    }),

    loadFromServerExamsPagesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_EXAMS_PAGES_SUCCESS,
        payload: data,
    }),

    loadFromServerExamsPagesError: error => ({
        type: actions.LOAD_FROM_SERVER_EXAMS_PAGES_ERROR,
        payload: {error},
    }),

    loadFromServerExams: data => ({
        type: actions.LOAD_FROM_SERVER_EXAMS,
        payload: data
    }),

    loadFromServerExamsSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_EXAMS_SUCCESS,
        payload: data,
    }),

    loadFromServerExamsError: error => ({
        type: actions.LOAD_FROM_SERVER_EXAMS_ERROR,
        payload: {error},
    }),

    loadFromServerExam: data => ({
        type: actions.LOAD_FROM_SERVER_EXAM,
        payload: data
    }),

    loadFromServerExamSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_EXAM_SUCCESS,
        payload: data,
    }),

    loadFromServerExamError: error => ({
        type: actions.LOAD_FROM_SERVER_EXAM_ERROR,
        payload: {error},
    }),

    saveIntoServerExam: (data, actionName = 'add', onSuccessMessage, callback) => ({
        type: actions.SAVE_INTO_SERVER_EXAMS,
        payload: {data, actionName, onSuccessMessage, callback},
    }),
};
export default actions;
