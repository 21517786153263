import { all, takeEvery, put } from 'redux-saga/effects'
import actions from './actions'
import api from '../../api/'
import commonActions from '../common/actions'

function* loadFromServerExam({ payload }) {
    console.log("loadFromServerExam", payload);

    const { examUuid, courseUuid } = payload;
    try {
        let results = yield api.studentGetExam({ examUuid, courseUuid });

        const { ExamData } = results;

        if (ExamData) {
            yield put(actions.loadFromServerExamSuccess({ exam: ExamData }))
        }

    } catch (e) {
        yield put(actions.loadFromServerExamError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* startExam({ payload }) {
    const { examUuid, courseUuid } = payload;
    try {
        let results = yield api.studentStartExam({ examUuid, courseUuid })

        const { ExamData, ExamResult } = results

        if (ExamData) {
            yield put(actions.loadFromServerExamSuccess({ ExamData: ExamData }));
        }

        if (ExamResult) {
            yield put(actions.loadFromServerExamSuccess({ ExamResult: ExamResult }));
        }

    } catch (e) {
        yield put(actions.startExamError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* saveAnswers({payload}) {
    const {data, callback} = payload
    const {answers, taskUuid, examUuid} = data
    if (answers && taskUuid && examUuid) {
        try {
            let results = yield api.studentPutAnswersExam({answers, taskUuid, examUuid})

            if (results) {
                callback && callback();
            }

        } catch (e) {
            yield put(actions.startExamError({...e}))
            yield put(commonActions.showMessage('error', e.toString()))
        }
    } else {
        yield put(actions.startExamError({}))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_EXAM, loadFromServerExam),
        takeEvery(actions.START_EXAM, startExam),
        takeEvery(actions.SAVE_EXAM_ANSWERS, saveAnswers),
    ])
}
