import logout from '../redux/logout'

export default class UserService {
    constructor() {
        this.BASE_URL = process.env.NODE_ENV === 'production' ? '/api/v1' : '/api/v1'
    }

    _callApi(endpoint, payload = {}) {
        let options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        if (payload !== undefined) {
            if (payload instanceof FormData) {
                options.body = payload
                delete options.headers['Content-Type']
            } else {
                options.body = JSON.stringify(payload)
            }
        }

        return fetch(`${this.BASE_URL}/${endpoint}`, options)
            .then(async (res) => {
                if (res.status >= 200 && res.status < 300) {
                    let contentType = res.headers.get('content-type')
                    if (contentType.indexOf('application/json') === 0)
                        return res.json()
                    else
                        return res.blob()
                } else {
                    throw await res.json()
                }
            }, err => {
                console.log('Error', err)
            }).catch((e) => {
                const {message} = e
                if (message === 'unauthorized-request') {

                } else {
                    console.log(e)
                }
            })
    }

    getSiteConfig() {
        return this._callApi('c/getSiteConfig')
    }

    register({name, email, password, phoneNumber}) {
        return this._callApi('register', {name, email, password, phoneNumber})
    }

    login({email, password}) {
        return this._callApi('login', {email, password})
    }

    logout() {
        return this._callApi('logout')
    }

    getUserInfo() {
        return this._callApi('getUserInfo')
    }

    sendPasswordRecoveryLink({ email }) {
        return this._callApi('sendPasswordRecoveryLink', { email })
    }

    resetPassword({ token, password }) {
        return this._callApi('resetPassword', { token, password })
    }

    ///////////////////////////////////////////////////////////
    // Student API
    ///////////////////////////////////////////////////////////

    getAllCourses() {
        return this._callApi('s/getAllCourses')
    }

    getEvents() {
        return this._callApi('s/getEvents')
    }

    getCourseListPagesStudent(options) {
        return this._callApi('s/getCourseListPages', options)
    }

    studentReviewOnlineLesson({onLineLessonUuid, description, rating}) {
        return this._callApi('s/reviewOnlineLesson', {onLineLessonUuid, description, rating})
    }

    getCourseListPageStudent({pageToken}) {
        return this._callApi('s/getCourseListPage', {pageToken})
    }

    getCourseDetails({courseUuid}) {
        return this._callApi('s/getCourseDetails', {courseUuid})
    }

    getCourseStudent({courseUuid}) {
        return this._callApi('s/getCourse', {courseUuid})
    }

    getLessonDetails({lessonUuid}) {
        return this._callApi('s/getLessonDetails', {lessonUuid})
    }

    getAvailableTeachers() {
        return this._callApi('s/getAvailableTeachers')
    }

    getTeacherTimetable({teacherUuid, startDate, endDate}) {
        return this._callApi('s/getTeacherTimetable', {teacherUuid, startDate, endDate})
    }

    reserveOnlineLesson({teacherUuid, startDate, duration, courseUuid}) {
        if (courseUuid) {
            return this._callApi('s/reserveOnlineLesson', { teacherUuid, startDate, duration: parseInt(duration, 10), courseUuid });
        } else {
            return this._callApi('s/reserveOnlineLesson', { teacherUuid, startDate, duration: parseInt(duration, 10) })
        }
    }

    getPurchasedCourses() {
        return this._callApi('s/getPurchasedCourses')
    }

    purchaseCourse(options) {
        return this._callApi('s/purchaseCourse', options)
    }

    getPurchasedCourseLesson({lessonUuid, purchasedCourseUuid}) {
        return this._callApi('s/getPurchasedCourseLesson', {lessonUuid, purchasedCourseUuid})
    }

    putAnswersLessonTask({purchasedCourseUuid, lessonUuid, taskUuid, answers}) {
        return this._callApi('s/putAnswersLessonTask', {purchasedCourseUuid, lessonUuid, taskUuid, answers})
    }

    studentGetOnlineLessons() {
        return this._callApi('s/getOnlineLessons', {})
    }

    studentGetHomeTasks() {
        return this._callApi('s/getHomeTasks', {})
    }

    studentGetHomeTask({homeTaskUUID}) {
        return this._callApi('s/getHomeTask', {homeTaskUUID})
    }

    studentGetAccessToken({onLineLessonUuid}) {
        return this._callApi('s/getAccessToken', {onLineLessonUuid})
    }

    studentGetOnlineLessonInfo({onLineLessonUuid}) {
        return this._callApi('s/getOnlineLessonInfo', {onLineLessonUuid})
    }

    studentDeleteOnlineLessons({lessonUuid}) {
        return this._callApi('s/deleteOnlineLesson', {lessonUuid})
    }

    studentGetSertificates() {
        return this._callApi('s/getSertificates')
    }

    // subscriptions

    getSubscriptionInfo() {
        return this._callApi('s/getSubscriptionInfo');
    }

    purchaseSubscription({ paymentType }) {
        return this._callApi('s/purchaseSubscription', { paymentType });
    }

    ///////////////////////////////////////////////////////////
    // Admin API
    ///////////////////////////////////////////////////////////

    // courses

    getCourseList() {
        return this._callApi('a/getCourseList')
    }

    getCourseListPages(options) {
        return this._callApi('a/getCourseListPages', options)
    }

    getCourseListPage({pageToken}) {
        return this._callApi('a/getCourseListPage', {pageToken})
    }

    getCourse({courseUuid}) {
        return this._callApi('a/getCourse', {courseUuid})
    }

    addCourse({courseData}) {
        return this._callApi('a/addCourse', {courseData})
    }

    updateCourse({courseUuid, courseData}) {
        return this._callApi('a/updateCourse', {courseUuid, courseData})
    }

    removeCourse({courseUuid}) {
        return this._callApi('a/removeCourse', {courseUuid})
    }

    // files

    getCourseFileListPages(options) {
        return this._callApi('a/getCourseFileListPages', options)
    }

    getCourseFileListPage({pageToken}) {
        return this._callApi('a/getCourseFileListPage', {pageToken})
    }

    removeCourseFile({courseFileUuid}) {
        return this._callApi('a/removeCourseFile', {courseFileUuid})
    }

    uploadCourseFile({file}) {
        let formData = new FormData()
        formData.append('file', file)
        return this._callApi('a/uploadCourseFile', formData)
    }

    getCourseFileInfo({courseFileUuid}) {
        return this._callApi('a/getCourseFileInfo', {courseFileUuid})
    }

    // tasks

    addTask({taskData}) {
        return this._callApi('a/addTask', {taskData})
    }

    getTaskListPages(options) {
        return this._callApi('a/getTaskListPages', options)
    }

    getTaskListPage({pageToken}) {
        return this._callApi('a/getTaskListPage', {pageToken})
    }

    removeTask({taskUuid}) {
        return this._callApi('a/removeTask', {taskUuid})
    }

    getTask({taskUuid}) {
        return this._callApi('a/getTask', {taskUuid})
    }

    updateTask({taskUuid, taskData}) {
        return this._callApi('a/updateTask', {taskUuid, taskData})
    }

    // lessons

    addLesson({lessonData}) {
        return this._callApi('a/addLesson', {lessonData})
    }

    getLessonsListPages(options) {
        return this._callApi('a/getLessonListPages', options)
    }

    getLessonsListPage({pageToken}) {
        return this._callApi('a/getLessonListPage', {pageToken})
    }

    removeLesson({lessonUuid}) {
        return this._callApi('a/removeLesson', {lessonUuid})
    }

    getLesson({lessonUuid}) {
        return this._callApi('a/getLesson', {lessonUuid})
    }

    updateLesson({lessonUuid, lessonData}) {
        return this._callApi('a/updateLesson', {lessonUuid, lessonData})
    }

    // Exams

    addExam({examData}) {
        return this._callApi('a/addExam', {examData})
    }

    updateExam({examUuid, examData}) {
        return this._callApi('a/updateexam', {examUuid, examData})
    }

    removeExam({examUuid}) {
        return this._callApi('a/removeExam', {examUuid})
    }

    getExamsListPages(options) {
        return this._callApi('a/getExamsListPages', options)
    }

    getExamsListPage({pageToken}) {
        return this._callApi('a/getExamsListPage', {pageToken})
    }

    getExam({examUuid}) {
        return this._callApi('a/getExam', {examUuid})
    }

    // teacher

    getTeacherListPages(options) {
        return this._callApi('a/getTeacherListPages', options)
    }

    getTeacherListPage({pageToken}) {
        return this._callApi('a/getTeacherListPage', {pageToken})
    }

    addTeacher({teacherData}) {
        return this._callApi('a/addTeacher', {teacherData})
    }

    removeTeacher({teacherUuid}) {
        return this._callApi('a/removeTeacher', {teacherUuid})
    }

    getTeacher({teacherUuid}) {
        return this._callApi('a/getTeacher', {teacherUuid})
    }

    updateTeacher({teacherUuid, teacherData}) {
        return this._callApi('a/updateTeacher', {teacherUuid, teacherData})
    }

    getTeacherSchedule({teacherUuid, startDate, endDate}) {
        return this._callApi('a/getTeacherSchedule', {teacherUuid, startDate, endDate})
    }

    addTeacherScheduleRecord({teacherUuid, studentUuid, holiday, startDate, duration}) {
        return this._callApi('a/addTeacherScheduleRecord', {teacherUuid, studentUuid, holiday, startDate, duration})
    }

    removeTeacherScheduleRecord({recordUuid}) {
        return this._callApi('a/removeTeacherScheduleRecord', {recordUuid})
    }

    teacherGetOnlineLessons() {
        return this._callApi('t/getOnlineLessons', {})
    }

    teacherDeleteOnlineLessons({lessonUuid}) {
        return this._callApi('t/deleteOnlineLesson', {lessonUuid})
    }

    teacherGetAccessToken({onLineLessonUuid}) {
        return this._callApi('t/getAccessToken', {onLineLessonUuid})
    }

    teacherGetOnlineLessonTasks({onLineLessonUuid}) {
        return this._callApi('t/getOnlineLessonTasks', {onLineLessonUuid})
    }

    teacherFinishOnlineLesson({onLineLessonUuid}) {
        return this._callApi('t/finishOnlineLesson', {onLineLessonUuid})
    }

    teacherGetOnlineLessonInfo({onLineLessonUuid}) {
        return this._callApi('t/getOnlineLessonInfo', {onLineLessonUuid})
    }

    teacherReviewOnlineLesson({onLineLessonUuid, description, bonuses, sertificate = null}) {

        let formData = new FormData()
        formData.append('onLineLessonUuid', onLineLessonUuid)
        formData.append('description', description)
        formData.append('bonuses', bonuses)
        sertificate && formData.append('sertificate', sertificate)

        return this._callApi('t/reviewOnlineLesson', formData)
    }

    teacherGetHomeTask({homeTaskUUID}) {
        return this._callApi('t/getHomeTask', {homeTaskUUID})
    }

    // student

    getStudentListPages(options) {
        return this._callApi('a/getStudentListPages', options)
    }

    getStudentListPage({pageToken}) {
        return this._callApi('a/getStudentListPage', {pageToken})
    }

    addStudent({studentData}) {
        return this._callApi('a/addStudent', {studentData})
    }

    removeStudent({studentUuid}) {
        return this._callApi('a/removeStudent', {studentUuid})
    }

    getStudent({studentUuid}) {
        return this._callApi('a/getStudent', {studentUuid})
    }

    updateStudent({studentUuid, studentData}) {
        return this._callApi('a/updateStudent', {studentUuid, studentData})
    }

    // settings
    getSettings() {
        return this._callApi('a/getSettings')
    }

    addSetting({setting}) {
        return this._callApi('a/addSetting', {setting})
    }

    updateSetting({setting}) {
        return this._callApi('a/updateSetting', {setting})
    }

    removeSetting({key}) {
        return this._callApi('a/removeSetting', {key})
    }

    // password
    changeAdminCredentials({ email, oldPassword, newPassword }) {
        return this._callApi('a/changeAdminPassword', { email, oldPassword, newPassword })
    }

    ///////////////////////////////////////////////////////////
    // Teacher API
    ///////////////////////////////////////////////////////////

    getMyStudents() {
        return this._callApi('t/getMyStudents', {})
    }

    updateOnlineLesssonTask(data) {
        return this._callApi('t/updateOnlineLesssonTask', data)
    }

    updateOnlineLesssonHomeTask(data) {
        return this._callApi('t/updateOnlineLesssonHomeTask', data)
    }

    banMyStudent({studentUuid}) {
        return this._callApi('t/banMyStudent', {studentUuid})
    }

    unbanMyStudent({studentUuid}) {
        return this._callApi('t/unbanMyStudent', {studentUuid})
    }

    getMyTeacherSchedule({studentUuid, startDate, endDate}) {
        return this._callApi('t/getMyTeacherSchedule', {studentUuid, startDate, endDate})
    }

    ///////////////////////////////////////////////////////////
    // Admin Entranse Test API
    ///////////////////////////////////////////////////////////

    getEntranceTest() {
        return this._callApi('a/getEntranceTest')
    }

    updateEntranceTest({entranceTestUuid, entranceTestData}) {
        return this._callApi('a/updateEntranceTest', {entranceTestUuid, entranceTestData})
    }

    ///////////////////////////////////////////////////////////
    // Student Entranse Test API
    ///////////////////////////////////////////////////////////

    studentGetEntranceTest() {
        return this._callApi('s/getEntranceTest')
    }

    studentStartEntranceTest({entranceTestUuid}) {
        return this._callApi('s/startEntranceTest', {entranceTestUuid})
    }

    studentPutAnswersEntranceTest({answers, taskUuid, entranceTestUuid}) {
        return this._callApi('s/putAnswersEntranceTest', {answers, taskUuid, entranceTestUuid})
    }

    studentPutAnswersHomeTask({answers, homeTaskUUID, taskUuid}) {
        return this._callApi('s/putAnswersHomeTask', {answers, homeTaskUUID, taskUuid})
    }

    studentCompleteHomeTaskHomeTask({homeTaskUUID}) {
        return this._callApi('s/completeHomeTask', {homeTaskUUID})
    }
    // payment
    studentCreatePayment({amount}) {
        return this._callApi('p/createPayment', {amount})
    }

    studentPaymentDetails({paymentUUID}) {
        return this._callApi('p/getPaymentDetails', {paymentUUID})
    }

    ///////////////////////////////////////////////////////////
    // Student Exam API
    ///////////////////////////////////////////////////////////

    studentGetExam({ examUuid, courseUuid }) {
        return this._callApi('s/getExam', { examUuid, courseUuid });
    }

    studentStartExam({ examUuid, courseUuid }) {
        return this._callApi('s/startExam', { examUuid, courseUuid });
    }

    studentPutAnswersExam({answers, taskUuid, examUuid}) {
        return this._callApi('s/putAnswersExam', { answers, taskUuid, examUuid });
    }

    ///////////////////////////////////////////////////////////
    // Common API
    ///////////////////////////////////////////////////////////

    commonGetCourseLevels() {
        return this._callApi('c/getCourseLevels');
    }

    commonGetLessonTypes() {
        return this._callApi('c/getLessonTypes');
    }

    commonGetCourseCategories() {
        return this._callApi('c/getCourseCategories');
    }

    ///////////////////////////////////////////////////////////
    // Teacher Compositions API
    ///////////////////////////////////////////////////////////

    tGetCompositions () {
        return this._callApi('t/getCompositions');
    }

    tGetComposition ({ uuid }) {
        return this._callApi('t/getComposition', { uuid });
    }

    tSendCompositionReview({ uuid, review }) {
        return this._callApi('t/sendCompositionReview', { uuid, review });
    }
}

