import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {

        case actions.LOAD_FROM_SERVER_TEACHERS:
            return {
                ...state,
                isLoading: true,
            };

        case actions.LOAD_FROM_SERVER_TEACHERS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };

        case actions.LOAD_FROM_SERVER_TEACHERS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };

        case actions.LOAD_FROM_SERVER_TIMETABLE:
            return {
                ...state,
                ...payload,
                timetable:[],
                isLoading: true,
            };

        case actions.LOAD_FROM_SERVER_TIMETABLE_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false
            };

        case actions.LOAD_FROM_SERVER_TIMETABLE_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };

        default:
            return state;
    }
}
