const DOCUMENT = 'STUDENT_ENTRANCE_TEST_'

const actions = {
  LOAD_FROM_SERVER_ENTRANCE_TEST: DOCUMENT + 'LOAD_FROM_SERVER_ENTRANCE_TEST',
  LOAD_FROM_SERVER_ENTRANCE_TEST_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_ENTRANCE_TEST_SUCCESS',
  LOAD_FROM_SERVER_ENTRANCE_TEST_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_ENTRANCE_TEST_ERROR',

  START_ENTRANCE_TEST: DOCUMENT + 'START_ENTRANCE_TEST',
  START_ENTRANCE_TEST_SUCCESS: DOCUMENT + 'START_ENTRANCE_TEST_SUCCESS',
  START_ENTRANCE_TEST_ERROR: DOCUMENT + 'START_ENTRANCE_TEST_ERROR',

  SAVE_ENTRANCE_TEST_ANSWERS: DOCUMENT + 'SAVE_ENTRANCE_TEST_ANSWERS',

  loadFromServerEntranceTest: () => ({
    type: actions.LOAD_FROM_SERVER_ENTRANCE_TEST,
  }),

  loadFromServerEntranceTestSuccess: data => ({
    type: actions.LOAD_FROM_SERVER_ENTRANCE_TEST_SUCCESS,
    payload: data,
  }),

  loadFromServerEntranceTestError: error => ({
    type: actions.LOAD_FROM_SERVER_ENTRANCE_TEST_ERROR,
    payload: {error},
  }),

  startEntranceTest: (data) => ({
    type: actions.START_ENTRANCE_TEST,
    payload: data,
  }),

  startEntranceTestSuccess: data => ({
    type: actions.START_ENTRANCE_TEST_SUCCESS,
    payload: data,
  }),

  startEntranceTestError: error => ({
    type: actions.START_ENTRANCE_TEST_ERROR,
    payload: {error},
  }),

  saveEntranceTestAnswers: (data, callback) => ({
    type: actions.SAVE_ENTRANCE_TEST_ANSWERS,
    payload: {data, callback},
  }),

}
export default actions
