const DOCUMENT = 'STUDENT_';

const actions = {
    LOAD_FROM_SERVER_STUDENTS_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS_PAGES',
    LOAD_FROM_SERVER_STUDENTS_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS_PAGES_SUCCESS',
    LOAD_FROM_SERVER_STUDENTS_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS_PAGES_ERROR',

    LOAD_FROM_SERVER_STUDENTS: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS',
    LOAD_FROM_SERVER_STUDENTS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS_SUCCESS',
    LOAD_FROM_SERVER_STUDENTS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS_ERROR',

    LOAD_FROM_SERVER_STUDENT: DOCUMENT + 'LOAD_FROM_SERVER_STUDENT',
    LOAD_FROM_SERVER_STUDENT_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_STUDENT_SUCCESS',
    LOAD_FROM_SERVER_STUDENT_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_STUDENT_ERROR',

    SAVE_INTO_SERVER_STUDENT: DOCUMENT + 'SAVE_INTO_SERVER_STUDENT',

    CHANGE_STUDENT: DOCUMENT + 'CHANGE_STUDENT',
    EDIT_VIEW: DOCUMENT + 'EDIT_VIEW',
    CREATE_VIEW: DOCUMENT + 'CREATE_VIEW',

    loadFromServerStudentsPages: data => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS_PAGES,
        payload: data
    }),

    loadFromServerStudentsPagesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS_PAGES_SUCCESS,
        payload: data,
    }),

    loadFromServerStudentsPagesError: error => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS_PAGES_ERROR,
        payload: {error},
    }),

    loadFromServerStudents: data => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS,
        payload: data
    }),

    loadFromServerStudentsSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS_SUCCESS,
        payload: data,
    }),

    loadFromServerStudentsError: error => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS_ERROR,
        payload: {error},
    }),

    loadFromServerStudent: data => ({
        type: actions.LOAD_FROM_SERVER_STUDENT,
        payload: data
    }),

    loadFromServerStudentSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_STUDENT_SUCCESS,
        payload: data,
    }),

    loadFromServerStudentError: error => ({
        type: actions.LOAD_FROM_SERVER_STUDENT_ERROR,
        payload: {error},
    }),

    saveIntoServerStudent: (data, actionName = 'add', onSuccessMessage, callback) => {

        return (dispatch, getState) => {
            const selectedUUID = getState().adminStudent.selectedUUID;
            dispatch({
                type: actions.SAVE_INTO_SERVER_STUDENT,
                payload: {
                    data,
                    actionName,
                    onSuccessMessage,
                    callback,
                    selectedUUID: (actionName.toString() === 'delete' && data.uuid === selectedUUID) ? null : selectedUUID,
                },
            });
        }
    },

    changeStudent: uuid => ({
        type: actions.CHANGE_STUDENT,
        payload: uuid
    }),
    viewChange: view => ({
        type: actions.EDIT_VIEW,
        payload: view
    }),
    changeTocreateView: () => ({
        type: actions.CREATE_VIEW,
        payload: true
    })

};
export default actions;
