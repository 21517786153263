import { all, put, select, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import api from '../../api/'

const getUserInfo = (state) => {
    return state.Auth
}

function* loadLessonInfo({payload}) {

    const {user: userInfo} = yield select(getUserInfo)

    const {onLineLessonUuid} = payload

    try {

        const results = userInfo.role.toString() === 'student' ? yield api.studentGetOnlineLessonInfo({onLineLessonUuid}) : yield api.teacherGetOnlineLessonInfo({onLineLessonUuid})

        const { onlinelessonInfo } = results
        if ( onlinelessonInfo ) {
            yield put(actions.loadFromLessonInfoSuccess({ onlinelessonInfo }))
        }

    } catch (e) {
        yield put(actions.loadFromLessonInfoError({...e}))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_LESSON_INFO, loadLessonInfo),
    ])
}
