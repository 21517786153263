import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import actionsPortal from '../portal/actions';
import api from "../../api";
import commonActions from "../common/actions";

function* showFileManager({payload}) {

    yield put(actionsPortal.showComponent({
        component: () => import('../../containers/AdminFileManager'),
        props: payload
    }));
}

function* loadFromServerFiles({payload}) {

    try {

        const storeData = {}

        const {pageToken} = payload

        if (pageToken) {

            let results = yield api.getCourseFileListPage({pageToken})

            const {files} = results

            if (files) {
                storeData['Files'] = files;
            }

        } else {
            storeData['Files'] = [];
        }
        storeData['currentPage'] = pageToken
        yield put(actions.loadFromServerFilesSuccess(storeData))

    } catch (e) {
        yield put(actions.loadFromServerFilesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerFilesPages({payload}) {

    try {

        const {filter, itemsPerPage} = payload

        const data = {itemsPerPage}

        if (filter) {
            data.filter = {type: filter}
        }

        let results = yield api.getCourseFileListPages(data)

        const {pageTokens} = results

        // if (pageTokens) {

            const storeData = {}
            storeData['Pages'] = pageTokens || [];

            yield put(actions.loadFromServerFilesPagesSuccess(storeData))
            yield put(actions.loadFromServerFiles({pageToken: pageTokens[0] || null}))

        // }

    } catch (e) {
        yield put(actions.loadFromServerFilesPagesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.OPEN_FILE_MANAGER, showFileManager),
        takeEvery(actions.LOAD_FROM_SERVER_FILES_PAGES, loadFromServerFilesPages),
        takeEvery(actions.LOAD_FROM_SERVER_FILES, loadFromServerFiles),
    ]);
}
