const DOCUMENT = 'STUDENT_ONLINE_LESSON_'

const actions = {
    JOIN_IN_ROOM: DOCUMENT + 'JOIN_IN_ROOM',
    JOIN_IN_ROOM_SUCCESS: DOCUMENT + 'JOIN_IN_ROOM_SUCCESS',
    JOIN_IN_ROOM_ERROR: DOCUMENT + 'JOIN_IN_ROOM_ERROR',

    LOAD_FROM_SERVER_TASKS: DOCUMENT + 'LOAD_FROM_SERVER_TASKS',
    LOAD_FROM_SERVER_TASKS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TASKS_SUCCESS',
    LOAD_FROM_SERVER_TASKS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TASKS_ERROR',

    LEAVE_ROOM: DOCUMENT + 'LEAVE_ROOM',

    ADD_TRACK: DOCUMENT + 'ADD_TRACK',
    REMOVE_TRACK: DOCUMENT + 'REMOVE_TRACK',

    SEND_TASK_TO_STUDENT: DOCUMENT + 'SEND_TASK_TO_STUDENT',
    SHOW_TASK: DOCUMENT + 'SHOW_TASK',
    SEND_ANSWER_TO_TEACHER: DOCUMENT + 'SEND_ANSWER_TO_TEACHER',
    RECIVED_ANSWER_FROM_STUDENT: DOCUMENT + 'RECIVED_ANSWER_FROM_STUDENT',

    FINISH_LESSON: DOCUMENT + 'FINISH_LESSON',

    REDIRECT_TO_REVIEW_FORM: DOCUMENT + 'REDIRECT_TO_REVIEW_FORM',

    joinInRoom: data => ({
        type: actions.JOIN_IN_ROOM,
        payload: data,
    }),

    joinInRoomSuccess: data => ({
        type: actions.JOIN_IN_ROOM_SUCCESS,
        payload: data,
    }),

    joinInRoomError: error => ({
        type: actions.JOIN_IN_ROOM_ERROR,
        payload: {error},
    }),

    leaveRoom: () => ({
        type: actions.LEAVE_ROOM,
    }),

    addTrack: track => ({
        type: actions.ADD_TRACK,
        payload: track,
    }),
    removeTrack: track => ({
        type: actions.REMOVE_TRACK,
        payload: track,
    }),

    loadFromServerTasks: data => ({
        type: actions.LOAD_FROM_SERVER_TASKS,
        payload: data,
    }),

    loadFromServerTasksSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TASKS_SUCCESS,
        payload: data,
    }),

    loadFromServerTasksError: error => ({
        type: actions.LOAD_FROM_SERVER_TASKS_ERROR,
        payload: {error},
    }),

    sendTaskToStudent: payload => ({
        type: actions.SEND_TASK_TO_STUDENT,
        payload: payload,
    }),

    showTask: task => ({
        type: actions.SHOW_TASK,
        payload: task,
    }),

    sendAnswerToTeacher: payload => ({
        type: actions.SEND_ANSWER_TO_TEACHER,
        payload: payload,
    }),

    putAnswer: payload => ({
        type: actions.RECIVED_ANSWER_FROM_STUDENT,
        payload: payload,
    }),

    finishLesson: payload => ({
        type: actions.FINISH_LESSON,
        payload: payload,
    }),

    redirectToReviewForm: () => ({
        type: actions.REDIRECT_TO_REVIEW_FORM,
    }),
}
export default actions
