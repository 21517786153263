import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {
				case actions.LOAD_FROM_SERVER_COURSES_PAGES:
            return {
                ...state,
                isLoading: true
            };
	      case actions.LOAD_FROM_SERVER_COURSES_PAGES_SUCCESS:
	          return {
	              ...state,
	              ...payload,
	              isLoading: false,
	          };
	      case actions.LOAD_FROM_SERVER_COURSES_PAGES_ERROR:
		        return {
		            ...state,
		            ...payload,
		            isLoading: false
		       	 };

        case actions.LOAD_FROM_SERVER_COURSES:
            return {
                ...state,
                isLoading: true,
            };

        case actions.LOAD_FROM_SERVER_COURSES_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };

        case actions.LOAD_FROM_SERVER_COURSES_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
						};
						
				case actions.LOAD_FROM_SERVER_EVENTS:
					return {
							...state,
							isLoading: true,
					};

				case actions.LOAD_FROM_SERVER_EVENTS_SUCCESS:
						return {
								...state,
								...payload,
								isLoading: false,
						};

				case actions.LOAD_FROM_SERVER_EVENTS_ERROR:
						return {
								...state,
								...payload,
								isLoading: false
					};

        case actions.LOAD_FROM_SERVER_COURSE:
            return {
                ...state,
                course: {},
                isLoading: true,
            };

        case actions.LOAD_FROM_SERVER_COURSE_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };

        case actions.LOAD_FROM_SERVER_COURSE_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
						};
				case actions.LOAD_FROM_SERVER_AVAILABLE_TEACHERS:
            return {
                ...state,
                availableTeachers: {},
                isLoading: true,
            };

        case actions.LOAD_FROM_SERVER_AVAILABLE_TEACHERS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };

        case actions.LOAD_FROM_SERVER_AVAILABLE_TEACHERS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
						};
				case actions.PURCHAISE_COURSE_BY_STUDENT:
		        return {
		            ...state,
		            isLoading: true
						};
				case actions.PURCHAISE_COURSE_BY_STUDENT_SUCCESS:
		        return {
		            ...state,
		            isLoading: false
						};
				case actions.LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON:
            return {
                ...state,
                isLoading: true
            };
	      case actions.LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_SUCCESS:
	          return {
	              ...state,
	              ...payload,
	              isLoading: false,
	          };
	      case actions.LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_ERROR:
		        return {
		            ...state,
		            ...payload,
		            isLoading: false
		       	 };

        default:
            return state;
    }
}
