import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import authAction from '../../redux/auth/actions';
// import TopbarNotification from "./topbarNotification";
// import TopbarMessage from "./topbarMessage";
// import TopbarSearch from "./topbarSearch";
import TopbarUser from "./topbarUser";
// import TopbarAddtoCart from "./topbarAddtoCart";
import logopic from '../../image/logo.png';
import TopbarWrapper from "./topbar.style";
import { Modal, Form, Input } from 'antd';
import IntlMessages from "../../components/utility/intlMessages";
import notification from "../../components/notification";
import api from '../../api';
import { siteConfig } from '../../settings';

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const { logout } = authAction;
const FormItem = Form.Item;

class Topbar extends Component {

	logout = () => {
		this.props.logout();
	}

  showAdminPasswordModal = (e) => {
    this.setState({
      changeAdminPasswordModalVisible: true,
    })
  }

  hideAdminPasswordModal = () => {
    this.setState({
      changeAdminPasswordModalVisible: false
    })
  }

  changeAdminPassword = (e) => {
    api.changeAdminCredentials({
      email: this.state.adminEmail,
      oldPassword: this.state.oldAdminPassword || '',
      newPassword: this.state.newAdminPassword || '',
    }).then((r) => {
      if (r.ok) {
        notification('success', 'Success', r.msg);
        this.setState({
          changeAdminPasswordModalVisible: false
        })
      } else {
        notification('error', 'Error', r.msg);
      }
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      changeAdminPasswordModalVisible: false,
      adminEmail: this.props.user.email,
    }
  }

  render() {
    const { locale, user } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const changeAdminPasswordModalVisible = this.state.changeAdminPasswordModalVisible;
    const styling = {
      width: "100%",
			height: 80,
			backgroundColor: 'transparent',
			borderBottom: '1px solid #522342'
    };
    return (
      <TopbarWrapper>
        <Modal
          title="Admin password"
          visible={changeAdminPasswordModalVisible}
          onOk={this.changeAdminPassword}
          onCancel={this.hideAdminPasswordModal}
          >
          <Form>
            <FormItem label={<IntlMessages id="topbar.changeAdminPassword.email"/>}>
              <Input name="adminEmail" id="admin-email" value={this.state.adminEmail} onChange={this.handleChange}/>
            </FormItem>
            <FormItem label={<IntlMessages id="topbar.changeAdminPassword.old_password"/>}>
              <Input.Password name="oldAdminPassword" id="old-admin-password" onChange={this.handleChange}/>
            </FormItem>
            <FormItem label={<IntlMessages id="topbar.changeAdminPassword.new_password"/>}>
              <Input.Password name="newAdminPassword" id="new-admin-password" onChange={this.handleChange}/>
            </FormItem>
          </Form>
        </Modal>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            {/* <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
						/> */}
						<div className="header-logo">
							<img src={siteConfig.siteLogo || logopic} alt={siteConfig.siteName} style={{height: '95%', maxWidth: 'auto'}}/>
						</div>
          </div>

          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser user={user} logout={this.logout} changeAdminPassword={this.showAdminPasswordModal} locale={locale} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

function mapStateToProps(state) {
	return {
		...state.App,
    locale: state.LanguageSwitcher.language.locale,
		customizedTheme: state.ThemeSwitcher.topbarTheme,
		...state.Auth
	};
}

export default connect(mapStateToProps, {
	toggleCollapsed,
	logout
})(Topbar);