const optionsTeacher = [
  {
    key: 'shedule',
    label: 'sidebar.shedule',
  },
  {
    key: 'pupils',
		label: 'sidebar.my_pupils',
  },
  {
    key: 'compositions',
    label: 'sidebar.compositions',
  },
  {
    key: 'tasksbank',
    label: 'sidebar.tasks_bank',
	},
];
export default optionsTeacher;
