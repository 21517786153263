import { all, takeEvery, put } from 'redux-saga/effects'
import actions from './actions'
import api from '../../api/'
import commonActions from '../common/actions'

function* loadFromServerEntranceTest() {
    try {
        let results = yield api.studentGetEntranceTest()

        const {EntranceTestData} = results

        if (EntranceTestData) {
            yield put(actions.loadFromServerEntranceTestSuccess({entranceTest: EntranceTestData}))
        }

    } catch (e) {
        yield put(actions.loadFromServerEntranceTestError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* startEntranceTest({payload}) {
    const {entranceTestUuid} = payload
    try {
        let results = yield api.studentStartEntranceTest({entranceTestUuid})

        const {TestData, TestResult} = results

        if (TestData) {
            yield put(actions.loadFromServerEntranceTestSuccess({TestData: TestData}))
        }

        if (TestResult) {
            yield put(actions.loadFromServerEntranceTestSuccess({TestResult: TestResult}))
        }

    } catch (e) {
        yield put(actions.startEntranceTestError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* saveAnswers({payload}) {
    const {data, callback} = payload
    const {answers, taskUuid, entranceTestUuid} = data
    if (answers && taskUuid && entranceTestUuid) {
        try {
            let results = yield api.studentPutAnswersEntranceTest({answers, taskUuid, entranceTestUuid})

            if (results) {
                callback && callback();
            }

        } catch (e) {
            yield put(actions.startEntranceTestError({...e}))
            yield put(commonActions.showMessage('error', e.toString()))
        }
    } else {
        yield put(actions.startEntranceTestError({}))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_ENTRANCE_TEST, loadFromServerEntranceTest),
        takeEvery(actions.START_ENTRANCE_TEST, startEntranceTest),
        takeEvery(actions.SAVE_ENTRANCE_TEST_ANSWERS, saveAnswers),
    ])
}
