const DOCUMENT = 'TEACHER_';

const actions = {

    LOAD_FROM_SERVER_STUDENTS: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS',
    LOAD_FROM_SERVER_STUDENTS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS_SUCCESS',
		LOAD_FROM_SERVER_STUDENTS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_STUDENTS_ERROR',
		
		LOAD_FROM_SERVER_TEACHER_SCHEDULES: DOCUMENT + 'LOAD_FROM_SERVER_TEACHER_SCHEDULES',
    LOAD_FROM_SERVER_TEACHER_SCHEDULES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHER_SCHEDULES_SUCCESS',
    LOAD_FROM_SERVER_TEACHER_SCHEDULES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TEACHER_SCHEDULES_ERROR',


    loadFromServerStudents: () => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS
    }),

    loadFromServerStudentsSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS_SUCCESS,
        payload: data,
    }),

    loadFromServerStudentsError: error => ({
        type: actions.LOAD_FROM_SERVER_STUDENTS_ERROR,
        payload: {error},
		}),
		
		loadFromServerTeacherSchedules: data => ({
			type: actions.LOAD_FROM_SERVER_TEACHER_SCHEDULES,
			payload: data,
		}),
		loadFromServerTeacherSchedulesSuccess: data => ({
				type: actions.LOAD_FROM_SERVER_TEACHER_SCHEDULES_SUCCESS,
				payload: data,
		}),

		loadFromServerTeacherSchedulesError: error => ({
				type: actions.LOAD_FROM_SERVER_TEACHER_SCHEDULES_ERROR,
				payload: {error},
		}),

};
export default actions;
