const DOCUMENT = 'STUDENT_CHECKOUT_'

const actions = {
  LOAD_FROM_SERVER_PAYMENT_DETAILS: DOCUMENT + 'LOAD_FROM_SERVER_PAYMENT_DETAILS',

  loadFromServerPaymentDetails: data => ({
    type: actions.LOAD_FROM_SERVER_PAYMENT_DETAILS,
    payload: data,
  }),
}
export default actions
