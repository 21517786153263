import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import contactSagas from './contacts/saga';
import getLessonSagas from './getlesson/saga';
import commonSagas from './common/saga';
import coursesSagas from './courses/saga';
import adminCourseSagas from './adminCourse/saga';
import adminTaskSagas from './adminTask/saga';
import adminLessonSagas from './adminLesson/saga';
import adminFileManagerSagas from './adminFileManager/saga';
import adminTeacherSagas from './adminTeacher/saga';
import adminStudentSagas from './adminStudent/saga';
import adminEntranceTestSagas from './adminEntranceTest/saga';
import adminExamSaga from './adminExam/saga';
import studentEntranceTestSagas from './studentEntranceTest/saga';
import studentExamSagas from './studentExam/saga';
import onlineLessonSagas from './onlineLesson/saga';
import onlineLessonsSagas from './onlineLessons/saga';
import teacherStudentsSagas from './teacherStudents/saga';
import reviewLessonSagas from './reviewLesson/saga';
import checkoutSagas from './checkout/saga';
import subscriptionSagas from './subscription/saga';
// import invoicesSagas from './invoice/saga';
// import mailSagas from './mail/saga';
// import notesSagas from './notes/saga';
// import todosSagas from './todos/saga';
// import ecommerceSaga from './ecommerce/saga';
// import cardsSagas from './card/saga';
// import chatSagas from './chat/sagas';
// import youtubeSearchSagas from './youtubeSearch/sagas';
// import devSagas from '../customApp/redux/sagas';
// import articles from './articles/sagas';
// import investors from './investors/sagas';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        contactSagas(),
        getLessonSagas(),
        commonSagas(),
        coursesSagas(),
        adminCourseSagas(),
        adminTaskSagas(),
        adminFileManagerSagas(),
        adminLessonSagas(),
        adminTeacherSagas(),
        adminStudentSagas(),
        adminEntranceTestSagas(),
        adminExamSaga(),
        studentEntranceTestSagas(),
        studentExamSagas(),
        onlineLessonSagas(),
        onlineLessonsSagas(),
        teacherStudentsSagas(),
        reviewLessonSagas(),
        checkoutSagas(),
        subscriptionSagas(),
        // mailSagas(),
        // notesSagas(),
        // todosSagas(),
        // ecommerceSaga(),
        // cardsSagas(),
        // invoicesSagas(),
        // chatSagas(),
        // youtubeSearchSagas(),
        // devSagas(),
        // articles(),
        // investors(),
    ]);
}
