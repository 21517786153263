const actions = {
    SHOW_COMPONENT: "PORTAL_SHOW_COMPONENT",
    HIDE_COMPONENT: "PORTAL_HIDE_COMPONENT",

    showComponent: (component) => ({
        type: actions.SHOW_COMPONENT,
        payload: component
    }),

    hideCompomnent: () => ({
        type: actions.HIDE_COMPONENT
    })

};
export default actions;
