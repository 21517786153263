
export default {
  apiUrl: 'http://yoursite.com/api/'
};

let siteConfig = {
  siteName: 'IE-Exams',
  siteIcon: 'ion-flash',
  footerText: 'IE-Exams © 2019 All rights reserved'
};

function setSiteConfig (config) {
  siteConfig = config;
}

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};

const language = 'russian';

const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};

const Auth0Config = {
  domain: '',
  clientID: '',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'http://localhost:3000/auth0loginCallback'
    },
    languageDictionary: {
      title: 'IE-Exams',
      emailInputPlaceholder: 'neway02@yandex.ru',
      passwordInputPlaceholder: 'admin'
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#E14615',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000'
        }
      }
    }
  }
};

const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: ''
};

const googleConfig = {
  apiKey: '' //
};

const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: []
};

const youtubeSearchApi = '';

export {
  siteConfig,
  setSiteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi
};
