import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import commonActions from '../common/actions'
import api from "../../api";

function* loadFromServerTeacher({payload}) {
    try {
        const {uuid: teacherUuid} = payload

        if (!teacherUuid) return;


        let results = yield api.getTeacher({teacherUuid})

        const {teacherData} = results

        if (teacherData) {
            yield put(actions.loadFromServerTeacherSuccess({teacher: teacherData}))
        }

    } catch (e) {
        yield put(actions.loadFromServerTeacherError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerTeachers({payload}) {

    try {

        const storeData = {}

        const {pageToken} = payload

        if (pageToken) {

            let results = yield api.getTeacherListPage({pageToken})
            const {teachers} = results

            if (teachers) {
                storeData['teachers'] = teachers;
            }

        } else {
            storeData['teachers'] = [];
        }

        yield put(actions.loadFromServerTeachersSuccess(storeData))

    } catch (e) {
        yield put(actions.loadFromServerTeachersError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerTeachersPages({payload}) {

    try {

        const {filter, itemsPerPage} = payload

        const data = {itemsPerPage}

        if (filter) {
            data.filter = filter
        }

        let results = yield api.getTeacherListPages(data)
        const {pageTokens} = results

        const storeData = {}
        storeData['Pages'] = pageTokens || [];

        yield put(actions.loadFromServerTeachersPagesSuccess(storeData))
        yield put(actions.loadFromServerTeachers({pageToken: pageTokens[0]}))

    } catch (e) {
        yield put(actions.loadFromServerTeachersPagesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* storeIntoServer({payload}) {

    const {data, actionName, onSuccessMessage, callback} = payload;
    const {uuid} = data

    try {
        let results = null
        switch (actionName) {
            case 'delete':
                results = yield api.removeTeacher({teacherUuid: uuid})
                break;
            case 'update':
                results = yield api.updateTeacher({teacherUuid: uuid, teacherData: data})
                break;
            case 'add':
                results = yield api.addTeacher({teacherData: data})
                break;
            default:
                return;
        }

        if (results) {
            yield put(commonActions.showMessage('success', onSuccessMessage))
            callback && callback()
        }


    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerTeachersSchedules({payload}) {

    const {Pages, startDate, endDate} = payload
    if (!Pages || !startDate || !endDate) {
        return;
    }
    try {

        let schedulesData = []
        const promises = Pages.map(page => {
            return new Promise(async (resolve, reject) => {
                const results = await api.getTeacherListPage({pageToken: page})
                const {teachers} = results
                let _results = []
                if (teachers && teachers.length) {
                    const teachersUUID = []
                    teachers.forEach(teacher => {
                        teacher.uuid && teachersUUID.push(teacher.uuid)
                    })

                    if (teachersUUID.length) {
                        const schedulesResults = await api.getTeacherSchedule({
                            teacherUuid: teachersUUID,
                            startDate: startDate,
                            endDate: endDate
                        })

                        const {schedule} = schedulesResults || []

                        for (let uuid in schedule) {

                            const teacherInfo = teachers.find(teacher => teacher.uuid === uuid)

                            const _sh = schedule[uuid].map(s => {
                                return Object.assign({}, s, {teacherInfo: teacherInfo})
                            })

                            _results = _results.concat(_sh)
                        }

                    }
                }

                resolve(_results);

            })
        });

        const promisesResults = yield Promise.all(promises)

        promisesResults.map(d => {
            schedulesData = schedulesData.concat(d)
            return null
        })

        yield put(actions.loadFromServerSchedulesSuccess([{schedulesData: schedulesData}]))

    } catch (e) {
        yield put(actions.loadFromServerSchedulesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }

}

function* addTeacherSchedule({payload}) {

    const {data, holiday, callback} = payload;
    const {teacherUuid, studentUuid, startDate, duration} = data

    try {

        let results = yield api.addTeacherScheduleRecord({teacherUuid, studentUuid, holiday,startDate,duration})

        if (results && results.ok) {
            callback && callback()
        } else {
            yield put(commonActions.showMessage('error', results.message))
        }

    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* removeTeacherSchedule({payload}) {

    const {uuid, callback} = payload;

    try {

        let results = yield api.removeTeacherScheduleRecord({recordUuid: uuid})

        if (results && results.ok) {
            callback && callback()
        } else {
            yield put(commonActions.showMessage('error', results.message))
        }

    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_TEACHERS_PAGES, loadFromServerTeachersPages),
        takeEvery(actions.LOAD_FROM_SERVER_TEACHERS, loadFromServerTeachers),
        takeEvery(actions.LOAD_FROM_SERVER_TEACHER, loadFromServerTeacher),
        takeEvery(actions.SAVE_INTO_SERVER_TEACHER, storeIntoServer),
        takeEvery(actions.LOAD_FROM_SERVER_TEACHERS_SCHEDULES, loadFromServerTeachersSchedules),
        takeEvery(actions.ADD_TEACHER_SCHEDULE_RECORD, addTeacherSchedule),
        takeEvery(actions.REMOVE_TEACHER_SCHEDULE_RECORD, removeTeacherSchedule),
    ]);
}
