const DOCUMENT = 'STUDENT_EXAM_'

const actions = {
  LOAD_FROM_SERVER_EXAM: DOCUMENT + 'LOAD_FROM_SERVER_EXAM',
  LOAD_FROM_SERVER_EXAM_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_EXAM_SUCCESS',
  LOAD_FROM_SERVER_EXAM_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_EXAM_ERROR',

  START_EXAM: DOCUMENT + 'START_EXAM',
  START_EXAM_SUCCESS: DOCUMENT + 'START_EXAM_SUCCESS',
  START_EXAM_ERROR: DOCUMENT + 'START_EXAM_ERROR',

  SAVE_EXAM_ANSWERS: DOCUMENT + 'SAVE_EXAM_ANSWERS',

  loadFromServerExam: data => ({
    type: actions.LOAD_FROM_SERVER_EXAM,
    payload: data,
  }),

  loadFromServerExamSuccess: data => ({
    type: actions.LOAD_FROM_SERVER_EXAM_SUCCESS,
    payload: data,
  }),

  loadFromServerExamError: error => ({
    type: actions.LOAD_FROM_SERVER_EXAM_ERROR,
    payload: { error },
  }),

  startExam: (data) => ({
    type: actions.START_EXAM,
    payload: data,
  }),

  startExamSuccess: data => ({
    type: actions.START_EXAM_SUCCESS,
    payload: data,
  }),

  startExamError: error => ({
    type: actions.START_EXAM_ERROR,
    payload: { error },
  }),

  saveExamAnswers: (data, callback) => ({
    type: actions.SAVE_EXAM_ANSWERS,
    payload: { data, callback },
  }),

}
export default actions
