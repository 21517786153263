import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import api from '../../api/';
import commonActions from '../common/actions';
import _ from 'lodash';


function* loadFromServerLesson({payload}) {
    try {
        const {uuid: lessonUuid} = payload

        if (!lessonUuid) return;


        let results = yield api.getLesson({lessonUuid})

				const {lessonData} = results
				
        if (lessonData) {
            yield put(actions.loadFromServerLessonSuccess({lesson: lessonData}))
        }

    } catch (e) {
        yield put(actions.loadFromServerLessonError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerLessons({payload}) {

    try {

        const storeData = {}
        const {pageToken} = payload

        if (pageToken) {

            let results = yield api.getLessonsListPage({pageToken})
            const {lessons} = results

            if (lessons) {
                storeData['lessons'] = lessons;
            }

        } else {
						storeData['lessons'] = [];
				}

				yield put(actions.loadFromServerLessonsSuccess(storeData))
				

    } catch (e) {
        yield put(actions.loadFromServerLessonsError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerLessonsPages({payload}) {

    try {

        const {filter, itemsPerPage} = payload

        const data = {itemsPerPage}

        if (filter) {
            data.filter = filter
        }

        let results = yield api.getLessonsListPages(data)
        const {pageTokens} = results

        const storeData = {}
        storeData['Pages'] = pageTokens || [];

        yield put(actions.loadFromServerLessonsPagesSuccess(storeData))
				yield put(actions.loadFromServerLessons({pageToken: pageTokens[0]}))

    } catch (e) {
        yield put(actions.loadFromServerLessonsPagesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* storeIntoServer({ payload }) {
    const { data, actionName, onSuccessMessage, callback } = payload;
		const { uuid } = data

    try {

        let results = null,
						// getLesson = null,
						copyLesson = null;

        switch (actionName) {
						case 'delete':
                results = yield api.removeLesson({lessonUuid: uuid});
								break;
						case 'copy':
              let getLesson = yield api.getLesson({lessonUuid: uuid});
              if (getLesson) {
                let {lessonData} = getLesson;
                copyLesson = _.cloneDeep(lessonData);
                delete copyLesson['uuid']

                results = api.addLesson({lessonData: copyLesson});
              }

              break;
            case 'update':
                results = yield api.updateLesson({lessonUuid: uuid, lessonData: data});
                break;
            case 'add':
                results = yield api.addLesson({lessonData: data});
								break;
						case 'delete_group':

								if (data && data.length) {
									const promises = data.map(lesson => {
										return new Promise(async (resolve, reject) => {
												const removeLesson = await api.removeLesson({lessonUuid: lesson.uuid})
												resolve(removeLesson)
												reject(new Error("Load lessons failed"));
										})
									});
									results = yield Promise.all(promises);

								}
								break;
						case 'copy_group':
						  let promisesResults = []
              if (data && data.length) {
                const promises = data.map(lesson => {
                  return new Promise(async (resolve, reject) => {
                      const getLesson = await api.getLesson({lessonUuid: lesson.uuid})
                      if (getLesson) {
                        let {lessonData} = getLesson;
                        copyLesson = _.cloneDeep(lessonData);
                        delete copyLesson['uuid']
                      }
                      resolve(copyLesson)
                      reject(new Error("Load lessons failed"));
                  })
                });
      
                promisesResults = yield Promise.all(promises)
              }

              if (promisesResults) {
                const promises = promisesResults.map(lesson => {
                  return new Promise(async (resolve, reject) => {
                      const addLesson = await api.addLesson({lessonData: lesson})
                      resolve(addLesson)
                      reject(new Error("Load lessons failed"));
                  })
                });
                results = yield Promise.all(promises);
              }
            	break;
            default:
            	return;
        }

        if (results) {
          yield put(commonActions.showMessage('success', onSuccessMessage))
          callback && callback()
        }
    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_LESSONS_PAGES, loadFromServerLessonsPages),
        takeEvery(actions.LOAD_FROM_SERVER_LESSONS, loadFromServerLessons),
        takeEvery(actions.LOAD_FROM_SERVER_LESSON, loadFromServerLesson),
        takeEvery(actions.SAVE_INTO_SERVER_LESSON, storeIntoServer),
    ]);
}
