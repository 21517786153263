const DOCUMENT = 'FILES_';

const actions = {

    OPEN_FILE_MANAGER: DOCUMENT + 'OPEN_FILE_MANAGER',

    LOAD_FROM_SERVER_FILES_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_FILES_PAGES',
    LOAD_FROM_SERVER_FILES_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_FILES_PAGES_SUCCESS',
    LOAD_FROM_SERVER_FILES_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_FILES_PAGES_ERROR',

    LOAD_FROM_SERVER_FILES: DOCUMENT + 'LOAD_FROM_SERVER_FILES',
    LOAD_FROM_SERVER_FILES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_FILES_SUCCESS',
    LOAD_FROM_SERVER_FILES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_FILES_ERROR',

    LOAD_FROM_SERVER_FILE: DOCUMENT + 'LOAD_FROM_SERVER_FILE',
    LOAD_FROM_SERVER_FILE_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_FILE_SUCCESS',
    LOAD_FROM_SERVER_FILE_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_FILE_ERROR',

    // SAVE_INTO_SERVER_FILE: DOCUMENT + 'SAVE_INTO_SERVER_FILE',
    // loadFromServerCoursesPages,
    // loadFromServerCourses
    openFileManager: (type = 'files', onSelect) => ({
        type: actions.OPEN_FILE_MANAGER,
        payload: {type, onSelect},
    }),
    loadFromServerFilesPages: data => ({
        type: actions.LOAD_FROM_SERVER_FILES_PAGES,
        payload: data
    }),

    loadFromServerFilesPagesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_FILES_PAGES_SUCCESS,
        payload: data,
    }),

    loadFromServerFilesPagesError: error => ({
        type: actions.LOAD_FROM_SERVER_FILES_PAGES_ERROR,
        payload: {error},
    }),

    loadFromServerFiles: data => ({
        type: actions.LOAD_FROM_SERVER_FILES,
        payload: data
    }),

    loadFromServerFilesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_FILES_SUCCESS,
        payload: data,
    }),

    loadFromServerFilesError: error => ({
        type: actions.LOAD_FROM_SERVER_FILES_ERROR,
        payload: {error},
    }),
    //
    // loadFromServerFile: data => ({
    //     type: actions.LOAD_FROM_SERVER_FILE,
    //     payload: data
    // }),
    //
    // loadFromServerFileSuccess: data => ({
    //     type: actions.LOAD_FROM_SERVER_FILE_SUCCESS,
    //     payload: data,
    // }),
    //
    // loadFromServerFileError: error => ({
    //     type: actions.LOAD_FROM_SERVER_FILE_ERROR,
    //     payload: {error},
    // }),
    //
    // saveIntoServerFile: (data, actionName = 'add', onSuccessMessage, callback) => ({
    //     type: actions.SAVE_INTO_SERVER_FILE,
    //     payload: {data, actionName, onSuccessMessage, callback},
    // })

};
export default actions;
