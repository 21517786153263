import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {

        case actions.LOAD_FROM_SERVER_FILES_PAGES:
            return {
                ...state,
                ...payload,
                isLoading: true,
            };
        case actions.LOAD_FROM_SERVER_FILES_PAGES_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_FILES_PAGES_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_FILES:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_FILES_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_FILES_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        // case actions.LOAD_FROM_SERVER_TASK:
        //     return {
        //         ...state,
        //         ...{course: null},
        //         isLoading: true
        //     };
        // case actions.LOAD_FROM_SERVER_TASK_SUCCESS:
        //     return {
        //         ...state,
        //         ...payload,
        //         isLoading: false,
        //     };
        // case actions.LOAD_FROM_SERVER_TASK_ERROR:
        //     return {
        //         ...state,
        //         ...payload,
        //         isLoading: false
        //     };
        default:
            return state;
    }
}
