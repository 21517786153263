import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
		{type, payload}
){
    switch (type) {
        case actions.LOAD_FROM_SERVER_ENTRANCE_TEST:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_ENTRANCE_TEST_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_ENTRANCE_TEST_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };

        default:
            return state;
    }
}
