import Auth from './auth/reducer';
import App from './app/reducer';
// import Mails from './mail/reducer';
import Calendar from './calendar/reducer';
// import Box from './box/reducer';
// import Notes from './notes/reducer';
// import Todos from './todos/reducer';
import Contacts from './contacts/reducer';
// import Cards from './card/reducer';
// import Chat from './chat/reducers';
// import DynamicChartComponent from './dynamicEchart/reducer';
// import Ecommerce from './ecommerce/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
// import Invoices from './invoice/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import adminCourse from './adminCourse/reducer';
import adminTask from './adminTask/reducer';
import adminLesson from './adminLesson/reducer';
import adminTeacher from './adminTeacher/reducer';
import adminStudent from './adminStudent/reducer';
import adminFileManager from './adminFileManager/reducer';
import adminExam from './adminExam/reducer';
import portal from './portal/reducer';
// import studentOnlineLessons from './studentOnlineLessons/reducer';
// import studentOnlineLesson from './studentOnlineLesson/reducer';
import teacherStudents from './teacherStudents/reducer';
import studentEntranceTest from './studentEntranceTest/reducer';
import studentExam from './studentExam/reducer';
import onlineLessons from './onlineLessons/reducer';
import onlineLesson from './onlineLesson/reducer';
import reviewLesson from './reviewLesson/reducer';
// import YoutubeSearch from './youtubeSearch/reducers';
// import DevReducers from '../customApp/redux/reducers';
// import Articles from './articles/reducers';
// import Investors from './investors/reducers';
import GetLesson from './getlesson/reducer';
import Courses from './courses/reducer';
import adminEntranceTest from './adminEntranceTest/reducer';
import subscription from './subscription/reducer';

export default {
    Auth,
    App,
    ThemeSwitcher,
    LanguageSwitcher,
    // Mails,
    Calendar,
    // Box,
    // Notes,
    // Todos,
    Contacts,
    // Cards,
    // Chat,
    // DynamicChartComponent,
    // Ecommerce,
    // Invoices,
    // YoutubeSearch,
    // Articles,
    // Investors,
    // ...DevReducers,
    GetLesson,
    Courses,
    adminCourse,
    adminTask,
    adminLesson,
    adminFileManager,
    adminExam,
    portal,
    adminTeacher,
    adminStudent,
    adminEntranceTest,
    studentEntranceTest,
    studentExam,
    onlineLessons,
    onlineLesson,
    reviewLesson,
    // studentOnlineLessons,
	// 	studentOnlineLesson,
    teacherStudents,
    subscription,
};
