import actions from './actions';

const initState = {
    isLoading: false,
    selectedUUID: null,
    editView: false
};

export default function reducer(
    state = initState,
    {type, payload}
) {

    switch (type) {
        case actions.LOAD_FROM_SERVER_STUDENTS_PAGES:
            return {
                ...state,
                students:[],
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_STUDENTS_PAGES_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_STUDENTS_PAGES_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_STUDENTS:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_STUDENTS_SUCCESS:
            const students = payload['students'];

            return {
                ...state,
                students: state['students'].concat(students),
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_STUDENTS_ERROR:

            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_STUDENT:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_STUDENT_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_STUDENT_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.CHANGE_STUDENT:
            return {
                ...state,
                selectedUUID: payload,
                editView: false
            };
        case actions.EDIT_VIEW:
            return {
                ...state,
                editView: payload,
                createView: false
            };
        case actions.CREATE_VIEW:
            return {
                ...state,
                selectedUUID: null,
                createView: payload,
                editView: false
            };
        case actions.SAVE_INTO_SERVER_STUDENT:
            return {
                ...state,
                selectedUUID: payload.selectedUUID,
                student: null,
                editView: false
            };
        default:
            return state;
    }
}
