const DOCUMENT = 'SUBSCRIPTION_';

const actions = {
	LOAD_FROM_SERVER_SUBSCRIPTION: DOCUMENT + 'LOAD_FROM_SERVER_SUBSCRIPTION',
    LOAD_FROM_SERVER_SUBSCRIPTION_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_SUBSCRIPTION_SUCCESS',
    LOAD_FROM_SERVER_SUBSCRIPTION_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_SUBSCRIPTION_ERROR',

    PURCHASE_SUBSCRIPTION: DOCUMENT + 'PURCHASE_SUBSCRIPTION',
    PURCHASE_SUBSCRIPTION_SUCCESS: DOCUMENT + 'PURCHASE_SUBSCRIPTION_SUCCESS',
    PURCHASE_SUBSCRIPTION_ERROR: DOCUMENT + 'PURCHASE_SUBSCRIPTION_ERROR',

	loadFromServerSubscription: (data) => ({
		type: actions.LOAD_FROM_SERVER_SUBSCRIPTION,
		payload: data
	}),

    loadFromServerSubscriptionSuccess: (data) => ({
        type: actions.LOAD_FROM_SERVER_SUBSCRIPTION_SUCCESS,
        payload: data,
    }),

    loadFromServerSubscriptionError: (error) => ({
        type: actions.LOAD_FROM_SERVER_SUBSCRIPTION_ERROR,
        payload: {error},
    }),

    purchaseSubscription: (data) => ({
        type: actions.PURCHASE_SUBSCRIPTION,
        payload: data
    }),

    purchaseSubscriptionSuccess: (data) => ({
        type: actions.PURCHASE_SUBSCRIPTION_SUCCESS,
        payload: data,
    }),

    purchaseSubscriptionError: (error) => ({
        type: actions.PURCHASE_SUBSCRIPTION_ERROR,
        payload: {error},
    }),
};
export default actions;
