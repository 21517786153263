import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions';
import commonActions from '../common/actions';
import api from "../../api";
// import commonActions from '../common/actions'


// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

function* loadFromServerCoursesPages({payload}) {

	try {

			const {filter, itemsPerPage} = payload

			let filterName = ''
			const data = {itemsPerPage}
			data.filter = {}

			filter.length && filter.forEach(function(item) {
				switch (item) {
					case 'completed':
							data.filter = Object.assign({}, data.filter, {completed: true});
							filterName += item
							break;
					case 'notCompleted':
							data.filter = Object.assign({}, data.filter, {completed: false});
							filterName += item
							break;
					case 'purchased':
							data.filter = Object.assign({}, data.filter, {purchased: true});
							filterName += item
					break;
					case 'notPurchased':
							data.filter = Object.assign({}, data.filter, {purchased: false});
							filterName += item
					break;
					case 'byLevel':
							data.filter = Object.assign({}, data.filter, {category: 'byLevel'});
							filterName += item
					break;
					case 'special':
							data.filter = Object.assign({}, data.filter, {category: 'special'});
							filterName += item
					break;
					case 'other':
							data.filter = Object.assign({}, data.filter, {category: 'other'});
							filterName += item
					break;
					default:
							break;
				}
			});


			let results = yield api.getCourseListPagesStudent(data)

			const {pageTokens} = results

			if (pageTokens) {

					const storeData = {}
					storeData[filterName + 'Pages'] = pageTokens;

					yield put(actions.loadFromServerCoursesPagesSuccess(storeData))
					yield put(actions.loadFromServerCourses({pageToken: pageTokens[0] || null, filter: filterName}))

			}

	} catch (e) {
			yield put(actions.loadFromServerCoursesPagesError({...e}))
			yield put(commonActions.showMessage('error', e.toString()))
	}
}


function* loadFromServerCourses({payload}) {

	try {

			const storeData = {}

			const {pageToken, filter} = payload

			if (pageToken) {

					let results = yield api.getCourseListPageStudent({pageToken})

					results.courses = yield results.courses.map(async (c) => {
						const r = await api.getCourseStudent({ courseUuid: c.uuid });
						c.lessons = r.course.lessons;
						c.exams = r.course.exams;
						return c;
					})

					const { courses } = results

					if (courses) {
							storeData[filter + 'Courses'] = courses;
					}

			} else {
					storeData[filter + 'Courses'] = [];
			}

			yield put(actions.loadFromServerCoursesSuccess(storeData))

	} catch (e) {
			yield put(actions.loadFromServerCoursesError({...e}))
			yield put(commonActions.showMessage('error', e.toString()))
	}
}


function* loadFromServerEvents() {

	try {
			let results = yield api.getEvents()

			const {events} = results

			if (events) {
					yield put(actions.loadFromServerEventsSuccess({studentEvents: events}))
			}

	} catch (e) {
			yield put(actions.loadFromServerEventsError({...e}))
			yield put(commonActions.showMessage('error', e.toString()))
	}
}

function* loadFromServerCourse({payload = {}}) {

    try {
        const {uuid} = payload

        if (!uuid) return;

        let results = yield api.getCourseStudent({courseUuid : uuid})

				const {course} = results
				

        if (course) {
            yield put(actions.loadFromServerCourseSuccess({course: course}))
        }

    } catch (e) {
        yield put({type: actions.LOAD_FROM_SERVER_COURSE_ERROR, payload: {...e}});
    }
}

function* loadFromServerAvailableTeachers() {

	try {

			let results = yield api.getAvailableTeachers()

			const {teachers} = results
			

			if (teachers) {
					yield put(actions.loadFromServerAvailableTeachersSuccess({teachers: teachers}))
			}

	} catch (e) {
			yield put({type: actions.LOAD_FROM_SERVER_COURSE_ERROR, payload: {...e}});
	}
}

function* purchaiseCourseByStudent({payload}) {
	const {teacherUuid, courseUuid, payment, onCorfimText} = payload


	if (!courseUuid || !payment) return;

	let results = null;

	try {
		results = yield api.purchaseCourse({
			courseUuid: courseUuid,
			teacherUuid: teacherUuid,
			payment: payment
		});

	} catch (e) {
			yield put(commonActions.showMessage('error', e.toString()))
	}

	if (results && results.ok) {
		yield put(commonActions.showMessage('success', onCorfimText))
		yield put(actions.purchaiseCourseByStudentSuccess(results))
		yield put(authActions.checkAuthorization())
	} else {
		yield put(commonActions.showMessage('error', results.message))
	}

}


function* loadFromServerPurchasedCourseLesson({payload = {}}) {

	try {
			const {courseUuid, lessonUuid} = payload

			if (!courseUuid || !lessonUuid) return;

			let results = yield api.getPurchasedCourseLesson({
				purchasedCourseUuid : courseUuid,
				lessonUuid: lessonUuid
			})

			const {lessonData} = results
			

			if (lessonData) {
					yield put(actions.loadFromServerPurchasedCourseLessonSuccess(
						{lessonData}
					))
			} else {
				yield put(commonActions.showMessage('error', results.message))
			}

	} catch (e) {
		yield put(actions.loadFromServerPurchasedCourseLessonError({...e}))
		yield put(commonActions.showMessage('error', e.toString()))
	}
}

export default function* rootSaga() {
    yield all([
				takeEvery(actions.LOAD_FROM_SERVER_COURSES_PAGES, loadFromServerCoursesPages),
        takeEvery(actions.LOAD_FROM_SERVER_COURSES, loadFromServerCourses),
        takeEvery(actions.LOAD_FROM_SERVER_EVENTS, loadFromServerEvents),
        takeEvery(actions.LOAD_FROM_SERVER_COURSE, loadFromServerCourse),
				takeEvery(actions.LOAD_FROM_SERVER_AVAILABLE_TEACHERS, loadFromServerAvailableTeachers),
				takeEvery(actions.PURCHAISE_COURSE_BY_STUDENT, purchaiseCourseByStudent),
				takeEvery(actions.LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON, loadFromServerPurchasedCourseLesson)
    ]);
}
