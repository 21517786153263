import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { connect, LocalDataTrack } from 'twilio-video'
import actions from './actions'
import api from '../../api/'
import commonActions from '../common/actions'

const getUserInfo = (state) => {
    return state.Auth
}

let channel = null

// локальный канал данных
let dataTrack = null;

// let localTrack = [];

function* createEventChannel(room) {
    return yield eventChannel(emit => {

        const participantConnected = (participant) => {
            console.log('Participant "%s" connected', participant.identity)
            participant.on('trackSubscribed', track => {
                if (track.kind !== 'data') {
                    emit({type: 'trackSubscribed', track})
                } else {
                    track.on('message', message => {
                        const data = JSON.parse(message);
                        emit({type: 'newMessage', data})
                    });
                }
            })
            participant.on('trackUnsubscribed', track => emit({type: 'trackUnsubscribed', track}))
            // participant.on('disconnected', participant => emit({ type: 'disconnected', participant }));
            participant.on('disconnected', participant => participant.tracks.forEach(track => emit({
                type: 'disconnected',
                track: Object.assign(track, {sid: track.trackSid})
            })))
        }

        const disconnected = (room, error) => {
            // room.localParticipant.tracks.forEach(track => {
            //     const attachedElements = track.detach();
            //     attachedElements.forEach(element => element.remove());
            // });
        }

        const disconnect = () => {
            room.disconnect()
        }

        room.participants.forEach(
            partic => {
                partic.tracks.forEach(publication => {
                    if (publication.isSubscribed) {
                        emit({type: 'trackSubscribed', publication})
                    }
                })
                participantConnected(partic)
            }
        )

        room.on('participantConnected', participantConnected)
        room.on('disconnected', disconnected)
        window.addEventListener('beforeunload', disconnect)

        return () => {
            room.off('participantConnected', participantConnected)
            // room.off('disconnected', disconnected)
            window.removeEventListener('beforeunload', disconnect)
        }
    })
}

function* joinInRoom({payload}) {

    const {user: userInfo} = yield select(getUserInfo)

    try {

        const {onLineLessonUuid} = payload
        let results = userInfo.role.toString() === 'student'
          ? yield api.studentGetAccessToken({onLineLessonUuid})
          : yield api.teacherGetAccessToken({onLineLessonUuid})
        const {accessToken, room} = results

        if (!accessToken || !room) {
            yield put(actions.joinInRoomError({error: 'не удалось получить данные подключения'}))
            return false
        }

        // try {
        //   localTrack = yield createLocalTracks();
        // } catch (e) {
        //
        // }
        let roomConnect = null;
        try {
          roomConnect = yield connect(accessToken.token, {
            name: room.uniqueName,
            logLevel: 'info',
          })
        } catch (e) {
          roomConnect = yield connect(accessToken.token, {
            name: room.uniqueName,
            logLevel: 'info',
            tracks: [],
          })
        }

        // локальный медиа
        dataTrack = new LocalDataTrack();
        yield roomConnect.localParticipant.publishTrack(dataTrack);

        const tracks = Array.from(roomConnect.localParticipant.tracks.values())

        for (let i = 0; i < tracks.length; i++) {
            if (['audio', 'data'].indexOf(tracks[i].kind) === -1) {
                yield put(actions.addTrack(tracks[i].track))
            }
        }

        channel = yield call(createEventChannel, roomConnect)

        try {
            while (true) {

                const {type, track, data} = yield take(channel)

                switch (type) {
                    case 'trackSubscribed':
                        yield put(actions.addTrack(track))
                        break
                    case 'trackUnsubscribed':
                        yield put(actions.removeTrack(track))
                        break
                    case 'disconnected':
                        yield put(actions.removeTrack(track))
                        break
                    case 'newMessage':
                        const {command, data: dataMessage, from} = data;

                        if (userInfo.role === 'student' && from === 'teacher') {
                            switch (command) {
                                case 'showTask':
                                    yield put(actions.showTask({activeTask: Object.keys(dataMessage).length ? dataMessage : null}));
                                    break;
                                 case 'redirectToReviewForm':
                                    yield put(actions.redirectToReviewForm());
                                    break;
                                default:
                                    break;
                            }
                        }

                        if (userInfo.role === 'teacher' && from === 'student') {
                            switch (command) {
                                case 'answer':
                                    yield put(actions.putAnswer({answer: dataMessage}));
                                    break;
                                default:
                                    break;
                            }
                        }

                        break;
                    default:
                        break;
                }
            }
        } finally {
            roomConnect.disconnect()
        }
    } catch (e) {

        yield put(actions.joinInRoomError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
        console.log(e)
    }
}

function* leaveRoom() {
    try {
        channel.close()
    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerTasks({payload}) {

    const {user: userInfo} = yield select(getUserInfo)

    const {onLineLessonUuid} = payload
    try {

        const results = userInfo.role.toString() === 'student'
          ? {}
          : yield api.teacherGetOnlineLessonTasks({onLineLessonUuid})
        const {tasks} = results
        if (tasks) {
            yield put(actions.loadFromServerTasksSuccess({tasks}))
        }

    } catch (e) {
        yield put(actions.loadFromServerTasksError({...e}))
    }
}

function* sendTaskToStudent({payload}) {
    const {user: userInfo} = yield select(getUserInfo)
    dataTrack.send(JSON.stringify({
        from: userInfo.role,
        command: 'showTask',
        data: payload,
    }))
}

function* sendAnswerToTeacher({payload}) {
    const {user: userInfo} = yield select(getUserInfo)
    dataTrack.send(JSON.stringify({
        from: userInfo.role,
        command: 'answer',
        data: payload,
    }))
}

function* finishLesson({payload}) {

    try {
        const {uuid} = payload;

        const results = yield api.teacherFinishOnlineLesson({onLineLessonUuid: uuid})

        const {ok} = results
        if (ok) {
            yield put(actions.redirectToReviewForm());
            const {user: userInfo} = yield select(getUserInfo)
            dataTrack.send(JSON.stringify({
                from: userInfo.role,
                command: 'redirectToReviewForm',
            }))
        }

    } catch (e) {
        console.log(e)
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.JOIN_IN_ROOM, joinInRoom),
        takeEvery(actions.LEAVE_ROOM, leaveRoom),
        takeEvery(actions.LOAD_FROM_SERVER_TASKS, loadFromServerTasks),
        takeEvery(actions.SEND_TASK_TO_STUDENT, sendTaskToStudent),
        takeEvery(actions.SEND_ANSWER_TO_TEACHER, sendAnswerToTeacher),
        takeEvery(actions.FINISH_LESSON, finishLesson),
    ])
}
