import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius, boxShadow } from '../../settings/style-util';
import WithDirection from '../../settings/withDirection';

const TopbarDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: -12px -16px;
  width: 340px;
  min-width: 160px;
  flex-shrink: 0;
  ${borderRadius('8px')};
  ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
  ${transition()};

  @media only screen and (max-width: 767px) {
    width: 310px;
	}
	
	.menu-user {

		&__main-info {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			padding: 10px 26px 0;

			&__name {
				color: #522342;
				font-size: 14px;
				font-weight: 400;
				line-height: 1.3;
				margin-bottom: 3px;
			}

			&__level {
				color: #b4b4b4;
				font-size: 12px;
			}

			&__avatar {
				width: 50px;
				height: 50px;
				text-align: center;
				border-radius: 50%;
				display: block;
				position: relative;
				overflow: hidden;
				margin-right: 12px;
				flex-shrink: 0;

				img {
					position: absolute;
					max-width: 100%;
					height: auto;
					left: 0;
					right: 0;
					margin: auto;
					top: 50%;
					transform: translateY(-50%)
				}
			}
		}

		&__balance-helper {
			opacity: 0.8;
			color: #d51a22;
			font-size: 10px;
			line-height: 1;
			display: block;
		}

		&__list {
			&__item {
				padding: 10px 0;
				display: flex;
				align-items: center;
				padding: 10px 25px;
				${transition()};
				cursor: pointer;
				text-decoration: none;

				&:hover {
					background-color: ${palette('grayscale', 3)};
					text-decoration: none;
				}

				&__text {
					color: #b4b4b4;
					font-size: 14px;
					line-height: 1.3;
				}

				&__value {
					margin-right: 8px;
				}

				&__icon {
					margin-left: auto;
					text-align: right;
					color: #522342;
					font-size: 14px;
					font-weight: 600;

					i {
						font-size: 16px;
						color: #f6b31d;
					}

					.icon-blue {
						i {
							color: #0099de;
						}
					}
				}
			}
		}

	}

  .isoDropdownHeader {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 0px;
    padding: 15px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 14px;
      font-weight: 500;
      color: ${palette('text', 0)};
      text-align: center;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .isoDropdownBody {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: ${palette('grayscale', 6)};

    a {
      text-decoration: none;
    }

    .isoDropdownListItem {
      padding: 15px 30px;
      flex-shrink: 0;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      text-align: ${props => (props['data-rtl'] === 'rtl' ? 'right' : 'left')};
      width: 100%;
      border-bottom: 1px solid ${palette('border', 2)};
      ${transition()};

      &:hover {
        background-color: ${palette('grayscale', 3)};
      }

      .isoListHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
      }

      h5 {
        font-size: 13px;
        font-weight: 500;
        color: ${palette('text', 0)};
        margin-top: 0;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: ${palette('text', 2)};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .isoDate {
        font-size: 11px;
        color: ${palette('grayscale', 1)};
        flex-shrink: 0;
      }
    }
  }

  .isoViewAllBtn {
    font-size: 13px;
    font-weight: 500;
    color: ${palette('text', 2)};
    padding: 10px 15px 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${transition()};

    &:hover {
      color: ${palette('primary', 0)};
    }
  }

  .isoDropdownFooterLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 20px;

    a {
      font-size: 13px;
      font-weight: 500;
      color: ${palette('text', 0)};
      text-decoration: none;
      padding: 10px 20px;
      line-height: 1;
      border: 1px solid ${palette('border', 1)};
      display: flex;
      align-items: center;
      justify-content: center;
      ${transition()};

      &:hover {
        background-color: ${palette('primary', 0)};
        border-color: ${palette('primary', 0)};
        color: #ffffff;
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      color: ${palette('text', 0)};
      line-height: 1.3;
    }
  }

  &.withImg {
    .isoDropdownListItem {
      display: flex;
      flex-direction: row;

      .isoImgWrapper {
        width: 35px;
        height: 35px;
        overflow: hidden;
        margin: ${props =>
          props['data-rtl'] === 'rtl' ? '0 0 0 15px' : '0 15px 0 0'};
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: ${palette('grayscale', 9)};
        ${borderRadius('50%')};

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .isoListContent {
        width: 100%;
        display: flex;
        flex-direction: column;

        .isoListHead {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }

        h5 {
          margin-bottom: 0;
          padding: ${props =>
            props['data-rtl'] === 'rtl' ? '0 0 0 15px' : '0 15px 0 0'};
        }

        .isoDate {
          font-size: 11px;
          color: ${palette('grayscale', 1)};
          flex-shrink: 0;
        }

        p {
          white-space: normal;
          line-height: 1.5;
        }
      }
    }
  }

  &.topbarMail {
    @media only screen and (max-width: 519px) {
      right: -170px;
    }
  }

  &.topbarMessage {
    @media only screen and (max-width: 500px) {
      right: -69px;
    }
  }

  &.topbarNotification {
    @media only screen and (max-width: 500px) {
      right: -120px;
    }
  }

  &.topbarAddtoCart {
    @media only screen and (max-width: 465px) {
      right: -55px;
    }

    .isoDropdownHeader {
      margin-bottom: 0;
    }

    .isoDropdownBody {
      background-color: ${palette('grayscale', 6)};
      display: flex;
      flex-direction: column;

      .isoNoItemMsg {
        height: 100%;
        min-height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 30px;
          font-weight: 300;
          color: ${palette('grayscale', 1)};
          line-height: 1.2;
        }
      }
    }
  }

  &.isoUserDropdown {
    padding: 7px 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 320px;
    min-width: 160px;
    flex-shrink: 0;
    ${borderRadius('5px')};
    ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
    ${transition()};

    .isoDropdownLink {
      font-size: 13px;
      color: ${palette('text', 1)};
      line-height: 1.1;
      padding: 7px 15px;
      background-color: transparent;
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      ${transition()};

      &:hover {
        background-color: ${palette('secondary', 6)};
      }
    }
  }
`;

export default WithDirection(TopbarDropdownWrapper);
