import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {
        case actions.LOAD_FROM_SERVER_TASKS_PAGES:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_TASKS_PAGES_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_TASKS_PAGES_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_TASKS:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_TASKS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_TASKS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_TASK:
            return {
                ...state,
                ...{task: null},
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_TASK_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_TASK_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        default:
            return state;
    }
}
