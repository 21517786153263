import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import {Link} from 'react-router-dom';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
// import userpic from '../../image/user1.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import dummyUserpic from '../../image/dummy.jpg';
// import ContainerExamTest from "../Exam/containerExamTest.style";

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  
  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  changeAdminPassword = (e) => {
    e.preventDefault();
    this.hide();
    this.props.changeAdminPassword();
  }

  render() {

		const {user} = this.props

		const {userpic, name, level} = user || {}

    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
				<div className="menu-user">
					{
						!(user && user.role.toString() === 'admin')
								&& (<div className="menu-user__main-info">
											<div className="menu-user__main-info__avatar">
												<img src={userpic && userpic.uuid ? userpic.url : dummyUserpic} alt={name || 'Без имени'} />
											</div>
											<div className="menu-user__main-info__info">
												<div className="menu-user__main-info__name">{name || 'Без имени'}</div>
												<div className="menu-user__main-info__level">{`Уровень ${level || 0}`}</div>
											</div>
										</div>)
					}
					<ul className="menu-user__list">
						{
              (user && user.role === 'admin')
                && (
                  <li>
                    <a href="/#" className="menu-user__list__item" onClick={this.changeAdminPassword}>
                      <div className="menu-user__list__item__text">
                        <IntlMessages id="topbar.changeAdminPassword" />
                      </div>
                    </a>
                  </li>
                  )
            }
            {
              (user && user.role === 'teacher')
                && (
                  	(user.hasZoom ?
		                  <li>
		                    <a href="/#" className="menu-user__list__item" onClick={this.changeAdminPassword}>
		                      <div className="menu-user__list__item__text">
		                        Zoom подключен: {user.zoomData.first_name} {user.zoomData.last_name}
		                      </div>
		                    </a>
		                  </li>
		                	:
		                	<li>
		                    <a href={user.oauthZoomLink} className="menu-user__list__item">
		                      <div className="menu-user__list__item__text">
		                        Подключить Zoom
		                      </div>
		                    </a>
		                  </li>
		                )
                  )
            }
            {
							(user && user.role === 'student')
								&& (<><li>
											<div className="menu-user__list__item">
												<div className="menu-user__list__item__text">
													<IntlMessages id="themeSwitcher.balance" />
												</div>
												<div className="menu-user__list__item__icon">
													<span>{user.balance || 0} ₽</span>
                                                      <Link className="menu-user__balance-helper" to={`/dashboard/checkout`}>Пополнить</Link>
												</div>
											</div>
										</li>
										<li>
											<a className="menu-user__list__item" href="# ">
												<div className="menu-user__list__item__text" href="# ">
													<IntlMessages id="themeSwitcher.bonus" />
												</div>
												<div className="menu-user__list__item__icon" href="# ">
													<span className="menu-user__list__item__value">
														{user.bonuses || 0}
													</span>
													<span><Icon type="star" theme="filled" /></span>
												</div>
											</a>
										</li>
										<li>
                      <Link className="menu-user__list__item" to={`/dashboard/sertificates`}>
												<div className="menu-user__list__item__text" href="# ">
													<IntlMessages id="themeSwitcher.sertificate" />
												</div>
												<div className="menu-user__list__item__icon" href="# ">
													<span><Icon type="file-done" /></span>
												</div>
                      </Link>
										</li></>)
            }
						<li>
							<a href="/#" className="menu-user__list__item" onClick={this.props.logout}>
								<div className="menu-user__list__item__text">
									<IntlMessages id="themeSwitcher.logout" />
								</div>
	        		</a>
						</li>
					</ul>
				</div>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
					<Icon type="user" />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default connect(
  null,
  { logout }
)(TopbarUser);
