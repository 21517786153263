import {all, takeEvery, put, fork} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {clearToken} from '../../helpers/utility';
import actions from './actions';
import commonActions from '../common/actions';
import api from '../../api'

export function* loginRequest() {

    yield takeEvery('LOGIN_REQUEST', function* (payload) {
        try {

            const results = yield api.login(payload)
            if (results) {
                yield put({
                    type: actions.CHECK_AUTHORIZATION,
								})
						} else {
							yield put({type: actions.LOGIN_ERROR, payload: {}});
							yield put(commonActions.showMessage('error', 'Неверные данные'))
						}

        } catch (e) {
						yield put({type: actions.LOGIN_ERROR, payload: {...e}});
						yield put(commonActions.showMessage('error', e.toString()))
        }

    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        try {
            yield api.logout()    
        }
        catch (e) {
        }
        clearToken();
        yield put(push('/signin'));
    });
}

export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {

        try {

            const results = yield api.getUserInfo()

            if (results) {
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    payload: {...results},
                });
            }
        } catch (e) {
            clearToken();
            yield put(push('/signin'));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(logout)
    ]);
}
