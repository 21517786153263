import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import api from '../../api/';
import commonActions from '../common/actions';
import _ from 'lodash';

function* loadFromServerExam({payload}) {
    try {
        const {uuid: examUuid} = payload

        if (!examUuid) return;


        let results = yield api.getExam({examUuid})

				const {examData} = results
				
        if (examData) {
            yield put(actions.loadFromServerExamSuccess({exam: examData}))
        }

    } catch (e) {
        yield put(actions.loadFromServerExamError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerExams({payload}) {

    try {

        const storeData = {};
        const {pageToken} = payload;
        if (pageToken) {

            let results = yield api.getExamsListPage({pageToken})
            const {exams} = results

            if (exams) {
                storeData['exams'] = exams;
            }

        } else {
						storeData['exams'] = [];
				}

				yield put(actions.loadFromServerExamsSuccess(storeData))
				

    } catch (e) {
        yield put(actions.loadFromServerExamsError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerExamsPages({payload}) {

    try {

        const {filter, itemsPerPage} = payload

        const data = {itemsPerPage}

        if (filter) {
            data.filter = filter
        }

        let results = yield api.getExamsListPages(data)
        const {pageTokens} = results

        const storeData = {}
        storeData['Pages'] = pageTokens || [];

        yield put(actions.loadFromServerExamsPagesSuccess(storeData))
				yield put(actions.loadFromServerExams({pageToken: pageTokens[0]}))

    } catch (e) {
        yield put(actions.loadFromServerExamsPagesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* storeIntoServer({ payload }) {
  const {data, actionName, onSuccessMessage, callback} = payload;

  try {
    let results = null,
      // getExam = null,
      copyExam = null;

    switch (actionName) {
      case 'delete':
        results = yield api.removeExam({examUuid: data.uuid})
        break;
      case 'copy':
        let getExam = yield api.getExam({examUuid: data.uuid})
        if (getExam) {
          let {examData} = getExam;
          copyExam = _.cloneDeep(examData);
          delete copyExam['uuid'];
          delete copyExam['_id'];
          results = api.addExam({examData: copyExam})
        }

        break;
      case 'update':
        results = yield api.updateExam({examUuid: data.uuid, examData: data})
        break;
      case 'add':
        results = yield api.addExam({examData: data})
        break;
      case 'delete_group':
        if (data && data.length) {
          const promises = data.map(exam => {
            return new Promise(async (resolve, reject) => {
              const removeExam = await api.removeExam({examUuid: exam.uuid})
              resolve(removeExam)
              reject(new Error("Load exams failed"));
            })
          });
          results = yield Promise.all(promises);

        }
        break;
      case 'copy_group':
        let promisesResults = []
        if (data && data.length) {
          const promises = data.map(exam => {
            return new Promise(async (resolve, reject) => {
              const getExam = await api.getExam({ examUuid: exam.uuid })
              if (getExam) {
                let { examData } = getExam;
                copyExam = _.cloneDeep(examData);
                delete copyExam['uuid'];
                delete copyExam['_id'];
              }
              resolve(copyExam)
              reject(new Error("Load exams failed"));
            })
          });

          promisesResults = yield Promise.all(promises)
        }

        if (promisesResults) {
          const promises = promisesResults.map(exam => {
            return new Promise(async (resolve, reject) => {
              const addExam = await api.addExam({ examData: exam })
              resolve(addExam)
              reject(new Error("Load exams failed"));
            })
          });
          results = yield Promise.all(promises);
        }

        break;
      default:
        return;
    }


    if (!results.message) {
      yield put(commonActions.showMessage('success', onSuccessMessage))
      callback && callback()
    } else {
      yield put(commonActions.showMessage('error', results.message.toString()))
    }


  } catch (e) {
    yield put(commonActions.showMessage('error', e.toString()))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_SERVER_EXAMS_PAGES, loadFromServerExamsPages),
    takeEvery(actions.LOAD_FROM_SERVER_EXAMS, loadFromServerExams),
    takeEvery(actions.LOAD_FROM_SERVER_EXAM, loadFromServerExam),
    takeEvery(actions.SAVE_INTO_SERVER_EXAMS, storeIntoServer),
  ]);
}
