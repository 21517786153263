import actions from './actions'

const initState = {
    track: [],
    answers: [],
    isLoading: false,
    redirectToReviewForm: false,
}

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {
        case actions.LOAD_FROM_SERVER_LESSON_INFO:
            return {
                ...state,
                isLoading: true
            }
        case actions.LOAD_FROM_SERVER_LESSON_INFO_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            }
        case actions.LOAD_FROM_SERVER_LESSON_INFO_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            }

        default:
            return state
    }
}
