import actions from "./actions";

// const initState = { idToken: null };
const initState = {
    isLoading: false,
};

export default function authReducer(state = initState, {type, payload}) {

    switch (type) {
        case actions.LOGIN_SUCCESS:
            return {
                // idToken: action.token
                ...state,
                ...payload,
                isLoading: false
            };

        case actions.LOGIN_REQUEST:
            return {
                // idToken: action.token
                ...initState,
                isLoading: true
            };
        case actions.LOGIN_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOGOUT:
            return initState;
        default:
            return state;
    }
}
