import api from './api';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';

// import { AppContainer } from 'react-hot-loader'
// import { setConfig } from 'react-hot-loader'

// setConfig({ logLevel: 'debug' })
// ReactDOM.render(<DashApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

// if (!module.hot) {
api.getSiteConfig().then((response) => {
  if (response.siteConfig.favicon)
    document.getElementById('favicon').href = response.siteConfig.favicon;
  if (response.siteConfig.siteTitle)
    document.getElementById('site_title').innerHTML = response.siteConfig.siteTitle;
  const setSiteConfig = require('./settings').setSiteConfig;
  setSiteConfig(response.siteConfig);
  const React = require('react');
  const ReactDOM = require('react-dom');
  const DashApp = require('./dashApp').default;
  ReactDOM.render(<DashApp />, document.getElementById('root'))
});
// } else {
//     const render = () => {
//         ReactDOM.render(<Root />, document.getElementById('root'))
//     }
//     render()
//     module.hot.accept('./dashApp', () => {
//         render();
//     });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({onSuccess: () => {console.log('')}, onUpdate: () => {console.log('')}});
