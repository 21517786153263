import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import _ from 'lodash';

import optionsCourse from "./options-course";
import optionsLesson from "./options-lesson";
import optionsTeacher from "./options-teacher";
import optionsAdmin from "./options-admin";
// import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
// import Logo from "../../components/utility/logo";
// import sidebar from "../../components/algolia/sidebar";
import { siteConfig } from '../../settings';

const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
  setCollapsed,
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    // if (this.props.app.view === "MobileView") {
    //   setTimeout(() => {
    //     this.props.toggleCollapsed();
    //   }, 0);
    // }
  }
  hideOnClickOutside(element) {

    const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );

    const outsideClickListener = event => {
        if (!element.contains(event.target) && isVisible(element)) {
          this.props.setCollapsed();
          removeClickListener();
        }
    }

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener)
    }

    document.addEventListener('click', outsideClickListener)
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
		const { key, label, leftIcon, children } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
							{leftIcon 
								? <i className={leftIcon} />
								: ''
							}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
						{leftIcon 
							? <i className={leftIcon} />
							: ''
						}
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };
  render() {
    const {
      app,
      customizedTheme,
      customizedThemeButtom,
      toggleCollapsed,
      setCollapsed,
    } = this.props;

    const handleClick = (e) => {
      this.hideOnClickOutside(e.currentTarget);
      toggleCollapsed();
    }

    const { width } = app;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const mode = collapsed === true ? "vertical" : "inline";
    const role = _.get(this.props, 'user.role', '');

    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
			color: '#fff',
			fontSize: '16px',
    };
    const submenuColor = {
			color: '#fff',
    };
    return (
      <SidebarWrapper theme={siteConfig.theme}>
					{
						width <= 575
							? (<div className="mobile-trigger-btn-container">
										<i
											className={`ion-grid triggerBtn ${collapsed ? "menuCollapsed" : "menuOpen"}`}
											style={{ color: customizedThemeButtom.textColor }}
											onClick={handleClick}
											role="button"
										/>
								 </div>)
							: null
					}

        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          collapsedWidth={width >= 575 ? 56 : 10}
          width={width >= 575 ? 300 : 200}
          className="isomorphicSidebar"
          style={styling}
        >
					{
						width >= 575
							? (<div className="sidebar-trigger-btn-container">
										<i
											className={`ion-grid triggerBtn ${collapsed ? "menuCollapsed" : "menuOpen"}`}
											style={{ color: customizedThemeButtom.textColor }}
											onClick={toggleCollapsed}
											role="button"
										/>
								 </div>)
							: null
					}

          {/* <Scrollbars style={{ height: height - 200 }}> */}
					<div className="sidebar-scrollable-section">

					{
						role === 'student'
							&& (<>
									<section className="sidebar-menu-section">
										<div className="sidebar-menu-title">
											<IntlMessages id="sidebar.online_courses" />
										</div>
				            <Menu
				              onClick={this.handleClick}
				              theme="dark"
				              className="isoDashboardMenu"
				              mode={mode}
				              openKeys={collapsed ? [] : app.openKeys}
				              selectedKeys={app.current}
				              onOpenChange={this.onOpenChange}
				            >
				              {optionsCourse.map(singleOption => {
                        if (singleOption.key == 'buy-subscription' && siteConfig.hasSubscription == false) {
                          return null;
                        }
                        if (singleOption.key == 'gettested' && siteConfig.hasEntranceTest == false) {
                          return null;
                        }
				                return this.getMenuItem({ submenuStyle, submenuColor, singleOption })
				                }
                      )}
				            </Menu>
									</section>

									<section className="sidebar-menu-section">
										<div className="sidebar-menu-title">
											<IntlMessages id="sidebar.online_lessons" />
										</div>
										<Menu
				              onClick={this.handleClick}
				              className="isoDashboardMenu"
				              mode={mode}
				              openKeys={collapsed ? [] : app.openKeys}
				              selectedKeys={app.current}
				              onOpenChange={this.onOpenChange}
				            >
				              {optionsLesson.map(singleOption =>
				                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
				              )}
				            </Menu>
									</section>
								 </>)
					}

					{
						role === 'teacher'
							&& (<section className="sidebar-menu-section">
										<div className="sidebar-menu-title">
											<IntlMessages id="sidebar.teacher_menu" />
										</div>
										<Menu
				              onClick={this.handleClick}
				              className="isoDashboardMenu"
				              mode={mode}
				              openKeys={collapsed ? [] : app.openKeys}
				              selectedKeys={app.current}
				              onOpenChange={this.onOpenChange}
				            >
				              {optionsTeacher.map(singleOption =>
				                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
				              )}
				            </Menu>
									</section>)
					}

					{
						role === 'admin'
							&& (<section className="sidebar-menu-section">
									<div className="sidebar-menu-title">
										<IntlMessages id="sidebar.admin_menu" />
									</div>
									<Menu
			              onClick={this.handleClick}
			              className="isoDashboardMenu"
			              mode={mode}
			              openKeys={collapsed ? [] : app.openKeys}
			              selectedKeys={app.current}
			              onOpenChange={this.onOpenChange}
			            >
			              {optionsAdmin.map(singleOption =>
			                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
			              )}
			            </Menu>
								</section>)
					}


          </div>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
		customizedTheme: state.ThemeSwitcher.sidebarTheme,
		customizedThemeButtom: state.ThemeSwitcher.topbarTheme,
		height: state.App.height,
		...state.Auth
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed, setCollapsed }
)(Sidebar);
