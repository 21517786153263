const optionsAdmin = [
    {
        key: 'teacherslist',
        label: 'sidebar.teachers_list',
    },
    {
        key: 'studentslist',
        label: 'sidebar.students_list',
    },
    {
        key: 'courseslist',
        label: 'sidebar.courses_list',
    },
    {
        key: 'lessonslist',
        label: 'sidebar.lessons_list',
    },
    {
        key: 'taskslist',
        label: 'sidebar.tasks_list',
    },
    {
        key: 'entrancetest',
        label: 'sidebar.entrancetest',
    },
    {
        key: 'settings',
        label: 'sidebar.settings',
    },
    // {
    //     key: 'tasksview',
    //     label: 'sidebar.tasks_view',
    // },
];
export default optionsAdmin;
