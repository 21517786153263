import Api from './api'

// class UserService {
// 	constructor () {
// 		this.BASE_URL = 'https://engl.quantrum.ru/api/v1';
// 		// this.BASE_URL = '/api/v1';
// 	}
//
// 	_callApi(endpoint, payload) {
// 		let options = {
// 			method: 'POST',
// 			credentials: 'include',
// 			headers: {
// 		      'Accept': 'application/json',
// 		      'Content-Type': 'application/json'
// 		    },
// 		};
// 		if (payload !== undefined) {
// 			options.body = JSON.stringify(payload);
// 		}
// 		return fetch(`${this.BASE_URL}/${endpoint}`, options)
// 			.then(async (res) => {
// 				if (res.status >= 200 && res.status < 300) {
//
//                     let contentType = res.headers.get("content-type");
//                     if (contentType.indexOf('application/json') === 0) {
//                         return res.json();
//                     } else {
//                         return res.blob();
//                     }
//
//                 } else {
// 					throw await res.json();
// 				}
// 			}, err => {
// 				console.log('Error', err);
// 			});
// 	}
//
// 	register ({ name, email, password }) {
// 		return this._callApi('register', { name, email, password });
// 	}
//
// 	login ({ email, password }) {
// 		return this._callApi('login', { email, password });
// 	}
//
// 	logout () {
// 		return this._callApi('logout');
// 	}
//
// 	getUserInfo() {
// 		return this._callApi('getUserInfo');
// 	}
//
// 	getAllCourses() {
// 		return this._callApi('s/getAllCourses');
// 	}
//
// 	getCourseDetails({ uuid }) {
// 		return this._callApi('s/getCourseDetails', { uuid });
// 	}
//
//     getAvailableTeachers() {
//         return this._callApi('s/getAvailableTeachers');
//     }
//
//     getTeacherTimetable({ teacherUuid, startDate, endDate }) {
//         return this._callApi('s/getTeacherTimetable', { teacherUuid, startDate,  endDate});
//     }
//
//     reserveOnlineLesson({ teacherUuid, startDate, duration }) {
//         return this._callApi('s/reserveOnlineLesson', { teacherUuid, startDate,  duration});
//     }
//
//     // admin Course
//     addCourse(courseData) {
//         return this._callApi('a/addCourse', {courseData: courseData});
//     }
//
//     getCourseListPages(params = {}) {
// 		let { filter, itemsPerPage } = params
//         filter = filter || {}
//         itemsPerPage = itemsPerPage || 10
//         return this._callApi('a/getCourseListPages', { filter, itemsPerPage });
//     }
//
//     getCourseListPage({pageToken}) {
//         return this._callApi('a/getCourseListPage', { pageToken });
//     }
//
//     getCourse({courseUuid}) {
//         return this._callApi('a/getCourse', { courseUuid });
//     }
//
//     updateCourse({courseUuid, courseData}) {
//         return this._callApi('a/updateCourse', {courseUuid, courseData});
// 	}
//
//     removeCourse({courseUuid}) {
//         return this._callApi('a/removeCourse', {courseUuid});
// 	}
//
// 	// admin files
//     getFileListPages(params = {}) {
//         let { filter, itemsPerPage } = params
//         filter = filter || {}
//         itemsPerPage = itemsPerPage || 10
//         return this._callApi('a/getFileListPages', params);
//     }
//
//     getFileListPage({pageToken}) {
//         return this._callApi('a/getFileListPage', { pageToken });
//     }
//
//     getCourseFileContent({ courseFileUuid }) {
//         return this._callApi('a/getCourseFileContent', { courseFileUuid });
//     }
//
//     removeCourseFile({ courseFileUuid }) {
//         return this._callApi('a/removeCourseFile', { courseFileUuid });
//     }
//
//
// }

const api = new Api()

// const getFileContent = async (uuid) => {
//     return await api.getCourseFileContent({courseFileUuid: uuid})
// }
//
// const removeFileContent = async (uuid) => {
//     return await api.removeCourseFile({courseFileUuid: uuid})
// }

export default api

// export {getFileContent, removeFileContent}
