import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import api from "../../api";
import commonActions from '../common/actions'


// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


function* loadFromServerStudents({payload = {}}) {

    try {

				let results = yield api.getMyStudents()
				
        const {students} = results

        if (students) {
            yield put(actions.loadFromServerStudentsSuccess({myStudents: students}))
        }

    } catch (e) {
        yield put({type: actions.LOAD_FROM_SERVER_STUDENTS_ERROR, payload: {...e}});
    }
}


function* loadFromServerTeacherSchedules({payload}) {

	const {startDate, endDate} = payload
	if (!startDate || !endDate) {
			return;
	}


	try {

			// let schedulesData = {}

			const schedulesResults = yield api.getMyTeacherSchedule({
					startDate: startDate,
					endDate: endDate
			})

			const {schedule} = schedulesResults || []

					// for (let uuid in schedule) {

					// 		const teacherInfo = teachers.find(teacher => teacher.uuid === uuid)

					// 		const _sh = schedule[uuid].map(s => {
					// 				return Object.assign({}, s, {teacherInfo: teacherInfo})
					// 		})

					// 		_results = _results.concat(_sh)
					// }

					if (schedule) {
						yield put(actions.loadFromServerTeacherSchedulesSuccess([{schedulesData: schedule}]))
					}


	} catch (e) {
			yield put(actions.loadFromServerTeacherSchedulesError({...e}))
			yield put(commonActions.showMessage('error', e.toString()))
	}

}



export default function* rootSaga() {
    yield all([
				takeEvery(actions.LOAD_FROM_SERVER_STUDENTS, loadFromServerStudents),
				takeEvery(actions.LOAD_FROM_SERVER_TEACHER_SCHEDULES, loadFromServerTeacherSchedules),
    ]);
}
