import {all, takeEvery, fork} from 'redux-saga/effects';
import actions from './actions';
import notification from "../../components/notification";

function* showMessage() {
    yield takeEvery(actions.SHOW_MESSAGE, function* (payload) {
        let {message, typeMessage} = payload
        yield notification(typeMessage, message)
    });
}

export default function* rootSaga() {
    yield all([
        fork(showMessage)
    ]);
}
