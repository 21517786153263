import actions from './actions';

const initState = {
		isLoading: false,
		myStudents: [],
		schedules: {
			isLoading: false
		},
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {

        case actions.LOAD_FROM_SERVER_STUDENTS:
            return {
                ...state,
                isLoading: true,
            };

        case actions.LOAD_FROM_SERVER_STUDENTS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };

        case actions.LOAD_FROM_SERVER_STUDENTS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
						};
				case actions.LOAD_FROM_SERVER_TEACHER_SCHEDULES:
							return {
									...state,
									schedules: Object.assign({}, state.schedules, {isLoading: true})
							};
				case actions.LOAD_FROM_SERVER_TEACHER_SCHEDULES_SUCCESS:
							return {
									...state,
									schedules: Object.assign(
											{},
											state.schedules,
											{isLoading: false}, ...payload
									)
							};
				case actions.LOAD_FROM_SERVER_TEACHER_SCHEDULES_ERROR:
							return {
									...state,
									schedules: Object.assign(
											{},
											state.schedules,
											{isLoading: false}, ...payload
									),
							};

        default:
            return state;
    }
}
