import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import commonActions from '../common/actions'
import api from "../../api";

function* loadFromServerCourse({payload}) {
    try {
        const {uuid: courseUuid} = payload

        if (!courseUuid) return;


        let results = yield api.getCourse({courseUuid})

        const {course} = results

        if (course) {
            yield put(actions.loadFromServerCourseSuccess({course: course}))
        }

    } catch (e) {
        yield put(actions.loadFromServerCourseError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerCourses({payload}) {

    try {

        const storeData = {}

        const {pageToken, filter} = payload

        if (pageToken) {

            let results = yield api.getCourseListPage({pageToken})
            const {courses} = results

            if (courses) {
                storeData[filter + 'Courses'] = courses;
            }

        } else {
            storeData[filter + 'Courses'] = [];
        }

        yield put(actions.loadFromServerCoursesSuccess(storeData))

    } catch (e) {
        yield put(actions.loadFromServerCoursesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerCoursesPages({payload}) {

    try {

        const {filter, itemsPerPage} = payload

        const data = {itemsPerPage}

        switch (filter) {
            case 'published':
                data.filter = {published: true}
                break;
            case 'unpublished':
                data.filter = {published: false}
                break;
            default:
                break;
        }

        let results = yield api.getCourseListPages(data)
        const {pageTokens} = results

        if (pageTokens) {

            const storeData = {}
            storeData[filter + 'Pages'] = pageTokens;

            yield put(actions.loadFromServerCoursesPagesSuccess(storeData))
            yield put(actions.loadFromServerCourses({pageToken: pageTokens[0] || null, filter: filter}))

        }

    } catch (e) {
        yield put(actions.loadFromServerCoursesPagesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* storeIntoServer({payload}) {

    const {data, actionName, onSuccessMessage, callback} = payload;
    const {uuid} = data

    try {
        let results = null
        switch (actionName) {
            case 'delete':
                results = yield api.removeCourse({courseUuid: uuid})
                break;
            case 'update':
                results = yield api.updateCourse({courseUuid: uuid, courseData: data})
                break;
            case 'add':
                results = yield api.addCourse({courseData: data})
                break;
            default:
                return;
        }

        if (results) {
            yield put(commonActions.showMessage('success', onSuccessMessage))
            callback && callback()
        }


    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_COURSES_PAGES, loadFromServerCoursesPages),
        takeEvery(actions.LOAD_FROM_SERVER_COURSES, loadFromServerCourses),
        takeEvery(actions.LOAD_FROM_SERVER_COURSE, loadFromServerCourse),
        takeEvery(actions.SAVE_INTO_SERVER_COURSE, storeIntoServer),
    ]);
}
