import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {
        case actions.LOAD_FROM_SERVER_ONLINE_LESSONS:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_ONLINE_LESSONS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_ONLINE_LESSONS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
						};
				case actions.LOAD_FROM_SERVER_HOMETASKS:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_HOMETASKS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_HOMETASKS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
						};
				case actions.LOAD_FROM_SERVER_HOMETASK:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_HOMETASK_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_HOMETASK_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        default:
            return state;
    }
}
