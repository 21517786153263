const DOCUMENT = 'ENTRANCE_TEST_';

const actions = {
    LOAD_FROM_SERVER_ENTRANCE_TEST: DOCUMENT + 'LOAD_FROM_SERVER_ENTRANCE_TEST',
    LOAD_FROM_SERVER_ENTRANCE_TEST_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_ENTRANCE_TEST_SUCCESS',
    LOAD_FROM_SERVER_ENTRANCE_TEST_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_ENTRANCE_TEST_ERROR',

    SAVE_INTO_SERVER_ENTRANCE_TEST: DOCUMENT + 'SAVE_INTO_SERVER_ENTRANCE_TEST',

    loadFromServerEntranceTest: () => ({
        type: actions.LOAD_FROM_SERVER_ENTRANCE_TEST,
    }),

    loadFromServerEntranceTestSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_ENTRANCE_TEST_SUCCESS,
        payload: data,
    }),

    loadFromServerEntranceTestError: error => ({
        type: actions.LOAD_FROM_SERVER_ENTRANCE_TEST_ERROR,
        payload: {error},
    }),

    saveIntoServerEntranceTest: (data, onSuccessMessage, callback) => ({
        type: actions.SAVE_INTO_SERVER_ENTRANCE_TEST,
        payload: {data, onSuccessMessage, callback},
    })

};
export default actions;
