import antdRU from 'antd/lib/locale-provider/ru_RU';
import appLocaleData from 'react-intl/locale-data/ru';
import ruMessages from '../locales/ru_RU.json';

const ruLang = {
  messages: {
    ...ruMessages
  },
  antd: antdRU,
  locale: 'ru',
  data: appLocaleData
};
export default ruLang;
