import { connect } from 'react-redux';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import {hot} from "react-hot-loader/root";
import { Layout, ConfigProvider } from 'antd';
// import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
// import ThemeSwitcher from '../../containers/ThemeSwitcher';
import AppRouter from './AppRouter';
import AppHolder from './commonStyle';
import { siteConfig } from '../../settings';
import { AppLocale } from '../../dashApp';
import themes from '../../settings/themes';
import ReactResizeDetector from 'react-resize-detector';
// import IntlGlobalProvider from '../../languageProvider/provider';

import './global.css';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;


export class App extends Component {

	onResize = (contentWidth, height) => {
		let width = window.innerWidth;
		this.props.toggleAll(width, contentWidth, height);
	}


  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme } = this.props;
    const currentAppLocale = AppLocale[locale];
    // const appHeight = window.innerHeight;

    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          {/* <IntlGlobalProvider /> */}
                <ThemeProvider theme={themes[selectedTheme]}>
                  <AppHolder>
                    {/* <Layout style={{ height: appHeight }}> */}
                    <Layout style={{ height: '100vh' }}>
                      {/* <Debounce time="1000" handler="onResize">
                        <WindowResizeListener
                          onResize={windowSize =>
                            this.props.toggleAll(
                              windowSize.windowWidth,
                              windowSize.windowHeight
                            )
                          }
                        />
                      </Debounce> */}
                      <Topbar url={url} />
                      <Layout style={{flexDirection: 'row', overflow: 'hidden', height: 'calc(100vh - 132px)'}}>
                        <Sidebar url={url} />
                        <Layout
                          className="isoContentMainLayout"
                          // style={{
                          //   height: height
                          // }}
                        >
      									<Content
                            className="isomorphicContent"
                            style={{
                              padding: '45px 50px 0',
                              flexShrink: '0',
                              background: '#f9f8f8',
                              position: 'relative'
                            }}
                          >
      											<Debounce time="50" handler="onResize">
      												<ReactResizeDetector
      													handleWidth
      													handleHeight
      													onResize={(width, height) => this.onResize(width, height)}
      													/>
      											</Debounce>
      											<AppRouter url={url} />
                         </Content>
                        </Layout>
                      </Layout>
      								<Footer style={{height: '52px'}} dangerouslySetInnerHTML={{__html: siteConfig.footerText}}>
                      </Footer>
                      {/* <ThemeSwitcher /> */}
                    </Layout>
                  </AppHolder>
              </ThemeProvider>
          {/* </IntlGlobalProvider /> */}
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    height: state.App.height
  }),
  { logout, toggleAll }
)(hot(App));
