import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import commonActions from '../common/actions'
import api from "../../api";

function* loadFromServerStudent({payload}) {
    try {
        const studentUuid = payload

        if (!studentUuid) return;


        let results = yield api.getStudent({studentUuid})

        const {studentData} = results

        if (studentData) {
            yield put(actions.loadFromServerStudentSuccess({student: studentData}))
        }

    } catch (e) {
        yield put(actions.loadFromServerStudentError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerStudents({payload}) {

    try {

        const storeData = {}

        const {pageToken, callback} = payload

        if (pageToken) {

            let results = yield api.getStudentListPage({pageToken})
            const {students} = results

            storeData['students'] = students || [];

        } else {
            storeData['students'] = [];
        }

        yield put(actions.loadFromServerStudentsSuccess(storeData))
        callback && callback()
    } catch (e) {
        yield put(actions.loadFromServerStudentsError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* loadFromServerStudentsPages({payload}) {

    try {

        const {filter, itemsPerPage} = payload

        const data = {itemsPerPage}

        if (filter) {
            data.filter = filter
        }

        let results = yield api.getStudentListPages(data)
        const {pageTokens, total} = results

        const storeData = {}
        storeData['Pages'] = pageTokens || [];
        storeData['Total'] = total || 0;

        yield put(actions.loadFromServerStudentsPagesSuccess(storeData))
        yield put(actions.loadFromServerStudents({pageToken: pageTokens[0]}))

    } catch (e) {
        yield put(actions.loadFromServerStudentsPagesError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

function* storeIntoServer({payload}) {

    const {data, actionName, onSuccessMessage, callback} = payload;
    const {uuid} = data

    try {
        let results = null
        switch (actionName) {
            case 'delete':
                results = yield api.removeStudent({studentUuid: uuid})
                break;
            case 'update':
                results = yield api.updateStudent({studentUuid: uuid, studentData: data})
                break;
            case 'add':
                results = yield api.addStudent({studentData: data})
                break;
            default:
                return;
        }

        if (results && results.ok) {
            yield put(commonActions.showMessage('success', onSuccessMessage))
            callback && callback()
        } else {
            yield put(commonActions.showMessage('error', results.message))
        }


    } catch (e) {
        yield put(commonActions.showMessage('error', e.toString()))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_STUDENTS_PAGES, loadFromServerStudentsPages),
        takeEvery(actions.LOAD_FROM_SERVER_STUDENTS, loadFromServerStudents),
        takeEvery(actions.CHANGE_STUDENT, loadFromServerStudent),
        takeEvery(actions.SAVE_INTO_SERVER_STUDENT, storeIntoServer),
    ]);
}
