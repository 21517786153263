import React, {Component} from 'react';
import ReactDOM from "react-dom";
import connect from "react-redux/es/connect/connect";
const modalRoot = document.getElementById('portal');

class Portal extends Component {

    state = { Component: undefined };

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    async componentWillReceiveProps(nextProps) {

        const {component: newComponent} = nextProps
        const {component: oldComponent} = this.props

        if (!oldComponent || !newComponent || newComponent.toString() !== oldComponent.toString()) {

            if (newComponent) {
                const {default: Component} = await newComponent();

                this.setState({
                    component: <Component {...nextProps.props} />,
                });
            } else {

                this.setState({
                    component: null,
                });
            }
        }
    }

    render() {

        const Component = this.state.component || <div />;

        return ReactDOM.createPortal(
            Component,
            this.el,
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.portal
    };
}

export default connect(mapStateToProps)(Portal);
