const DOCUMENT = 'COURSES_';

const actions = {
		LOAD_FROM_SERVER_COURSES_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_PAGES',
    LOAD_FROM_SERVER_COURSES_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_PAGES_SUCCESS',
    LOAD_FROM_SERVER_COURSES_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_PAGES_ERROR',

    LOAD_FROM_SERVER_COURSES: DOCUMENT + 'LOAD_FROM_SERVER_COURSES',
    LOAD_FROM_SERVER_COURSES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_SUCCESS',
		LOAD_FROM_SERVER_COURSES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_ERROR',

    LOAD_FROM_SERVER_EVENTS: DOCUMENT + 'LOAD_FROM_SERVER_EVENTS',
    LOAD_FROM_SERVER_EVENTS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_EVENTS_SUCCESS',
    LOAD_FROM_SERVER_EVENTS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_EVENTS_ERROR',

    LOAD_FROM_SERVER_COURSE: DOCUMENT + 'LOAD_FROM_SERVER_COURSE',
    LOAD_FROM_SERVER_COURSE_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_COURSE_SUCCESS',
		LOAD_FROM_SERVER_COURSE_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_COURSE_ERROR',

		LOAD_FROM_SERVER_AVAILABLE_TEACHERS: DOCUMENT + 'LOAD_FROM_SERVER_AVAILABLE_TEACHERS',
    LOAD_FROM_SERVER_AVAILABLE_TEACHERS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_AVAILABLE_TEACHERS_SUCCESS',
		LOAD_FROM_SERVER_AVAILABLE_TEACHERS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_AVAILABLE_TEACHERS_ERROR',

		PURCHAISE_COURSE_BY_STUDENT: DOCUMENT + 'PURCHAISE_COURSE_BY_STUDENT',
		PURCHAISE_COURSE_BY_STUDENT_SUCCESS: DOCUMENT + 'PURCHAISE_COURSE_BY_STUDENT_SUCCESS',

		LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON: DOCUMENT + 'LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON',
    LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_SUCCESS',
    LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_ERROR',
		
		loadFromServerCoursesPages: data => ({
			type: actions.LOAD_FROM_SERVER_COURSES_PAGES,
			payload: data
		}),

		loadFromServerCoursesPagesSuccess: data => ({
				type: actions.LOAD_FROM_SERVER_COURSES_PAGES_SUCCESS,
				payload: data,
		}),

		loadFromServerCoursesPagesError: error => ({
				type: actions.LOAD_FROM_SERVER_COURSES_PAGES_ERROR,
				payload: {error},
		}),

    loadFromServerCourses: data => ({
				type: actions.LOAD_FROM_SERVER_COURSES,
				payload: data,
    }),

    loadFromServerCoursesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_COURSES_SUCCESS,
        payload: data,
    }),

    loadFromServerCoursesError: error => ({
        type: actions.LOAD_FROM_SERVER_COURSES_ERROR,
        payload: {error},
		}),
		
		loadFromServerEvents: () => ({
			type: actions.LOAD_FROM_SERVER_EVENTS,
		}),

		loadFromServerEventsSuccess: data => ({
				type: actions.LOAD_FROM_SERVER_EVENTS_SUCCESS,
				payload: data,
		}),

		loadFromServerEventsError: error => ({
				type: actions.LOAD_FROM_SERVER_EVENTS_ERROR,
				payload: {error},
		}),

    loadFromServerCourse: data => ({
        type: actions.LOAD_FROM_SERVER_COURSE,
        payload: data,
    }),

    loadFromServerCourseSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_COURSE_SUCCESS,
        payload: data,
    }),

    loadFromServerCourseError: error => ({
        type: actions.LOAD_FROM_SERVER_COURSE_ERROR,
        payload: {error},
		}),
		loadFromServerAvailableTeachers: data => ({
			type: actions.LOAD_FROM_SERVER_AVAILABLE_TEACHERS,
			payload: data,
		}),
		loadFromServerAvailableTeachersSuccess: data => ({
			type: actions.LOAD_FROM_SERVER_AVAILABLE_TEACHERS_SUCCESS,
			payload: data,
		}),
		loadFromServerAvailableTeachersError: error => ({
				type: actions.LOAD_FROM_SERVER_AVAILABLE_TEACHERS_ERROR,
				payload: {error},
		}),
		purchaiseCourseByStudent: data => ({
			type: actions.PURCHAISE_COURSE_BY_STUDENT,
			payload: data,
		}),
		purchaiseCourseByStudentSuccess: data => ({
			type: actions.PURCHAISE_COURSE_BY_STUDENT_SUCCESS,
			payload: data,
		}),

		loadFromServerPurchasedCourseLesson: data => ({
			type: actions.LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON,
			payload: data,
		}),

		loadFromServerPurchasedCourseLessonSuccess: data => ({
				type: actions.LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_SUCCESS,
				payload: data,
		}),

		loadFromServerPurchasedCourseLessonError: error => ({
				type: actions.LOAD_FROM_SERVER_PURCHASED_COURSE_LESSON_ERROR,
				payload: {error},
		}),

};
export default actions;
