const DOCUMENT = 'TEACHERS_';

const actions = {

    LOAD_FROM_SERVER_TEACHERS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS',
    LOAD_FROM_SERVER_TEACHERS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_SUCCESS',
    LOAD_FROM_SERVER_TEACHERS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_ERROR',

    LOAD_FROM_SERVER_TIMETABLE: DOCUMENT + 'LOAD_FROM_SERVER_TIMETABLE',
    LOAD_FROM_SERVER_TIMETABLE_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TIMETABLE_SUCCESS',
    LOAD_FROM_SERVER_TIMETABLE_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TIMETABLE_ERROR',
    RESERVE_LESSON: DOCUMENT + 'RESERVE_LESSON',

    loadFromServerTeachers: () => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS
    }),

    loadFromServerTeachersSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_SUCCESS,
        payload: data,
    }),

    loadFromServerTeachersError: error => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_ERROR,
        payload: {error},
    }),

    loadFromServerTimeTable: data => ({
        type: actions.LOAD_FROM_SERVER_TIMETABLE,
        payload: data
    }),

    loadFromServerTimeTableSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TIMETABLE_SUCCESS,
        payload: data,
    }),

    loadFromServerTimeTableError: error => ({
        type: actions.LOAD_FROM_SERVER_TIMETABLE_ERROR,
        payload: {error},
    }),

    reserveLesson: data => ({
        type: actions.RESERVE_LESSON,
        payload: data,
    })

};
export default actions;
