import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
	    case actions.LOAD_FROM_SERVER_SUBSCRIPTION:
            return {
                ...state,
                ...payload,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_SUBSCRIPTION_ERROR:
	        return {
	            ...state,
	            ...payload,
	            isLoading: false
	       	 };
        case actions.PURCHASE_SUBSCRIPTION:
            return {
                ...state,
                ...payload,
                isLoading: true
            };
        case actions.PURCHASE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.PURCHASE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
             };
        default:
            return state;
    }
}
