import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions';
import api from "../../api";
import commonActions from '../common/actions'

import { intl } from '../../languageProvider/provider'

function* loadFromServerTeachers({payload = {}}) {

    try {

        let results = yield api.getAvailableTeachers()

        const {teachers} = results

        if (teachers) {
            yield put(actions.loadFromServerTeachersSuccess({teachers: teachers}))
        }

    } catch (e) {
        yield put({type: actions.LOAD_FROM_SERVER_TEACHERS_ERROR, payload: {...e}});
    }
}


function* loadFromServerTimeTable({payload = {}}) {

    const {selectedId: teacherUuid, startDate, endDate} = payload

    if (!teacherUuid || !startDate || !endDate) {
			return yield put(actions.loadFromServerTimeTableSuccess({timetable: null}))
		};

    try {

        let results = yield api.getTeacherTimetable({
            teacherUuid: teacherUuid,
            startDate: startDate,
            endDate: endDate
        })

        const {timetable} = results

        if (timetable) {
            yield put(actions.loadFromServerTimeTableSuccess({timetable: timetable}))
        }

    } catch (e) {
        yield put({type: actions.LOAD_FROM_SERVER_TIMETABLE_ERROR, payload: {...e}});
    }
}

function* reserveLesson({payload}) {

    const {teacherUuid, startDate, onConfirmText, interval, courseUuid} = payload;

    try {

        const {ok, message} = yield api.reserveOnlineLesson({
            courseUuid: courseUuid,
            teacherUuid: teacherUuid,
            startDate: startDate,
            duration: 3559
        })

        if (ok) {
            yield put(commonActions.showMessage('success', onConfirmText))

            const {startDate, endDate} = interval
            yield put({type: actions.LOAD_FROM_SERVER_TIMETABLE, payload: {
                    selectedId: teacherUuid,
                    startDate: startDate,
                    endDate: endDate
            }});
            yield put({type: authActions.CHECK_AUTHORIZATION});

        } else {
            yield put(commonActions.showMessage('error', intl.formatMessage({id: message})))
        }

    } catch (e) {
        yield put(commonActions.showMessage('error', e.message))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_TEACHERS, loadFromServerTeachers),
        takeEvery(actions.LOAD_FROM_SERVER_TIMETABLE, loadFromServerTimeTable),
        takeEvery(actions.RESERVE_LESSON, reserveLesson),
    ]);
}
