const DOCUMENT = 'TEACHER_';

const actions = {
    LOAD_FROM_SERVER_TEACHERS_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_PAGES',
    LOAD_FROM_SERVER_TEACHERS_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_PAGES_SUCCESS',
    LOAD_FROM_SERVER_TEACHERS_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_PAGES_ERROR',

    LOAD_FROM_SERVER_TEACHERS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS',
    LOAD_FROM_SERVER_TEACHERS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_SUCCESS',
    LOAD_FROM_SERVER_TEACHERS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_ERROR',

    LOAD_FROM_SERVER_TEACHER: DOCUMENT + 'LOAD_FROM_SERVER_TEACHER',
    LOAD_FROM_SERVER_TEACHER_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHER_SUCCESS',
    LOAD_FROM_SERVER_TEACHER_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TEACHER_ERROR',

    LOAD_FROM_SERVER_TEACHERS_SCHEDULES: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_SCHEDULES',
    LOAD_FROM_SERVER_TEACHERS_SCHEDULES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_SCHEDULES_SUCCESS',
    LOAD_FROM_SERVER_TEACHERS_SCHEDULES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TEACHERS_SCHEDULES_ERROR',

    SAVE_INTO_SERVER_TEACHER: DOCUMENT + 'SAVE_INTO_SERVER_TEACHER',

    ADD_TEACHER_SCHEDULE_RECORD: DOCUMENT + 'ADD_TEACHER_SCHEDULE_RECORD',
    REMOVE_TEACHER_SCHEDULE_RECORD: DOCUMENT + 'REMOVE_TEACHER_SCHEDULE_RECORD',

    loadFromServerTeachersPages: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_PAGES,
        payload: data
    }),

    loadFromServerTeachersPagesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_PAGES_SUCCESS,
        payload: data,
    }),

    loadFromServerTeachersPagesError: error => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_PAGES_ERROR,
        payload: {error},
    }),

    loadFromServerTeachers: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS,
        payload: data
    }),

    loadFromServerTeachersSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_SUCCESS,
        payload: data,
    }),

    loadFromServerTeachersError: error => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_ERROR,
        payload: {error},
    }),

    loadFromServerTeacher: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHER,
        payload: data
    }),

    loadFromServerTeacherSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHER_SUCCESS,
        payload: data,
    }),

    loadFromServerTeacherError: error => ({
        type: actions.LOAD_FROM_SERVER_TEACHER_ERROR,
        payload: {error},
    }),

    saveIntoServerTeacher: (data, actionName = 'add', onSuccessMessage, callback) => ({
        type: actions.SAVE_INTO_SERVER_TEACHER,
        payload: {data, actionName, onSuccessMessage, callback},
    }),

    loadFromServerSchedules: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_SCHEDULES,
        payload: data,
    }),
    loadFromServerSchedulesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_SCHEDULES_SUCCESS,
        payload: data,
    }),

    loadFromServerSchedulesError: error => ({
        type: actions.LOAD_FROM_SERVER_TEACHERS_SCHEDULES_ERROR,
        payload: {error},
    }),

    addTeacherScheduleRecord: (data, holiday = false, callback) => ({
        type: actions.ADD_TEACHER_SCHEDULE_RECORD,
        payload: {data, holiday, callback},
    }),

    removeScheduleRecord: (uuid, callback) => ({
        type: actions.REMOVE_TEACHER_SCHEDULE_RECORD,
        payload: {uuid, callback},
    }),

};
export default actions;
