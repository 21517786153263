import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { LocaleProvider, ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import ProviderService from './redux/store';
import PublicRoutes from './router';
import themes from './settings/themes';
import AppLocale from './languageProvider';
import config, {
    getCurrentLanguage
} from './containers/LanguageSwitcher/config';
import { themeConfig, siteConfig } from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
//import zoom from './zoom';

const history = createBrowserHistory();
const store = ProviderService.createProviderStore({}, history);

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || 'russia').locale];


class DashApp extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        await Boot();
        this.setState({
            loaded: true
        });
    }

    render() {
        const {loaded} = this.state

        return loaded ? (
            <ConfigProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <ThemeProvider theme={themes[themeConfig.theme]}>
                        <DashAppHolder>
                            <Provider store={store}>
                                <PublicRoutes history={history}/>
                            </Provider>
                        </DashAppHolder>
                    </ThemeProvider>
                </IntlProvider>
            </ConfigProvider>
        ) : <div className={'loader'}></div>
    }
}

export default DashApp;
export { AppLocale, store };
