import { all, takeEvery, put, select } from 'redux-saga/effects'
import actions from './actions'
import api from '../../api/'
import commonActions from '../common/actions'

const getUserInfo = (state) => {
    return state.Auth;
}

function* loadFromServerOnlineLessons() {

    const { user: userInfo } = yield select(getUserInfo);
    try {
        let results = userInfo.role === 'student' ? yield api.studentGetOnlineLessons() :  yield api.teacherGetOnlineLessons()

        const {onlinelessons} = results

        if (onlinelessons) {
            yield put(actions.loadFromServerOnlineLessonsSuccess({onlinelessons: onlinelessons}))
        }

    } catch (e) {
        yield put(actions.loadFromServerOnlineLessonsError({...e}))
        yield put(commonActions.showMessage('error', e.toString()))
    }
}


function* loadFromServerHomeTasks() {

  yield select(getUserInfo);

	try {
		// let results = userInfo.role == 'student' ? yield api.studentGetOnlineLessons() :  yield api.teacherGetOnlineLessons()
			let results = yield api.studentGetHomeTasks()

			const {homeTasks} = results

			if (homeTasks) {
					yield put(actions.loadFromServerHomeTasksSuccess({homeTasks: homeTasks}))
			}

	} catch (e) {
			yield put(actions.loadFromServerHomeTasksError({...e}))
			yield put(commonActions.showMessage('error', e.toString()))
	}
}

function* loadFromServerHomeTask({payload}) {

	const {homeTaskUUID} = payload;

	if (!homeTaskUUID) return;

	const { user: userInfo } = yield select(getUserInfo);

	try {
			const results = userInfo.role == 'student' ? yield api.studentGetHomeTask({homeTaskUUID}) :  yield api.teacherGetHomeTask({homeTaskUUID})

			const {homeTask} = results

			if (homeTask) {
					yield put(actions.loadFromServerHomeTaskSuccess({homeTask: homeTask}))
			}

	} catch (e) {
			yield put(actions.loadFromServerHomeTaskError({...e}))
			yield put(commonActions.showMessage('error', e.toString()))
	}
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_SERVER_ONLINE_LESSONS, loadFromServerOnlineLessons),
        takeEvery(actions.LOAD_FROM_SERVER_HOMETASKS, loadFromServerHomeTasks),
        takeEvery(actions.LOAD_FROM_SERVER_HOMETASK, loadFromServerHomeTask),
    ])
}
