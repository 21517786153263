const DOCUMENT = 'LESSON_';

const actions = {
    LOAD_FROM_SERVER_LESSONS_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_LESSONS_PAGES',
    LOAD_FROM_SERVER_LESSONS_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_LESSONS_PAGES_SUCCESS',
    LOAD_FROM_SERVER_LESSONS_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_LESSONS_PAGES_ERROR',

    LOAD_FROM_SERVER_LESSONS: DOCUMENT + 'LOAD_FROM_SERVER_LESSONS',
    LOAD_FROM_SERVER_LESSONS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_LESSONS_SUCCESS',
    LOAD_FROM_SERVER_LESSONS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_LESSONS_ERROR',

    LOAD_FROM_SERVER_LESSON: DOCUMENT + 'LOAD_FROM_SERVER_LESSON',
    LOAD_FROM_SERVER_LESSON_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_LESSON_SUCCESS',
    LOAD_FROM_SERVER_LESSON_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_LESSON_ERROR',

    SAVE_INTO_SERVER_LESSON: DOCUMENT + 'SAVE_INTO_SERVER_LESSON',

    loadFromServerLessonsPages: data => ({
        type: actions.LOAD_FROM_SERVER_LESSONS_PAGES,
        payload: data
    }),

    loadFromServerLessonsPagesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_LESSONS_PAGES_SUCCESS,
        payload: data,
    }),

    loadFromServerLessonsPagesError: error => ({
        type: actions.LOAD_FROM_SERVER_LESSONS_PAGES_ERROR,
        payload: {error},
    }),

    loadFromServerLessons: data => ({
        type: actions.LOAD_FROM_SERVER_LESSONS,
        payload: data
    }),

    loadFromServerLessonsSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_LESSONS_SUCCESS,
        payload: data,
    }),

    loadFromServerLessonsError: error => ({
        type: actions.LOAD_FROM_SERVER_LESSONS_ERROR,
        payload: {error},
    }),

    loadFromServerLesson: data => ({
        type: actions.LOAD_FROM_SERVER_LESSON,
        payload: data
    }),

    loadFromServerLessonSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_LESSON_SUCCESS,
        payload: data,
    }),

    loadFromServerLessonError: error => ({
        type: actions.LOAD_FROM_SERVER_LESSON_ERROR,
        payload: {error},
    }),

    saveIntoServerLesson: (data, actionName = 'add', onSuccessMessage, callback) => ({
        type: actions.SAVE_INTO_SERVER_LESSON,
        payload: { data, actionName, onSuccessMessage, callback },
    })

};
export default actions;
