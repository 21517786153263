import actions from './actions';

const initState = {
    isLoading: false,
    schedules: {
        isLoading: false
    },
};

export default function reducer(
    state = initState,
    {type, payload}
) {
    switch (type) {
        case actions.LOAD_FROM_SERVER_TEACHERS_PAGES:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_TEACHERS_PAGES_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_TEACHERS_PAGES_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_TEACHERS:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_TEACHERS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_TEACHERS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_TEACHER:
            return {
                ...state,
                ...{teacher: null},
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_TEACHER_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_TEACHER_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_TEACHERS_SCHEDULES:
            return {
                ...state,
                schedules: Object.assign({}, state.schedules, {isLoading: true})
            };
        case actions.LOAD_FROM_SERVER_TEACHERS_SCHEDULES_SUCCESS:
            return {
                ...state,
                schedules: Object.assign(
                    {},
                    state.schedules,
                    {isLoading: false}, ...payload
                )
            };
        case actions.LOAD_FROM_SERVER_TEACHERS_SCHEDULES_ERROR:
            return {
                ...state,
                schedules: Object.assign(
                    {},
                    state.schedules,
                    {isLoading: false}, ...payload
                ),
            };
        default:
            return state;
    }
}
