const DOCUMENT = 'COURSE_';

const actions = {
    LOAD_FROM_SERVER_COURSES_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_PAGES',
    LOAD_FROM_SERVER_COURSES_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_PAGES_SUCCESS',
    LOAD_FROM_SERVER_COURSES_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_PAGES_ERROR',

    LOAD_FROM_SERVER_COURSES: DOCUMENT + 'LOAD_FROM_SERVER_COURSES',
    LOAD_FROM_SERVER_COURSES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_SUCCESS',
    LOAD_FROM_SERVER_COURSES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_COURSES_ERROR',

    LOAD_FROM_SERVER_COURSE: DOCUMENT + 'LOAD_FROM_SERVER_COURSE',
    LOAD_FROM_SERVER_COURSE_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_COURSE_SUCCESS',
    LOAD_FROM_SERVER_COURSE_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_COURSE_ERROR',

    SAVE_INTO_SERVER_COURSE: DOCUMENT + 'SAVE_INTO_SERVER_COURSE',

    loadFromServerCoursesPages: data => ({
        type: actions.LOAD_FROM_SERVER_COURSES_PAGES,
        payload: data
    }),

    loadFromServerCoursesPagesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_COURSES_PAGES_SUCCESS,
        payload: data,
    }),

    loadFromServerCoursesPagesError: error => ({
        type: actions.LOAD_FROM_SERVER_COURSES_PAGES_ERROR,
        payload: {error},
    }),

    loadFromServerCourses: data => ({
        type: actions.LOAD_FROM_SERVER_COURSES,
        payload: data
    }),

    loadFromServerCoursesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_COURSES_SUCCESS,
        payload: data,
    }),

    loadFromServerCoursesError: error => ({
        type: actions.LOAD_FROM_SERVER_COURSES_ERROR,
        payload: {error},
    }),

    loadFromServerCourse: data => ({
        type: actions.LOAD_FROM_SERVER_COURSE,
        payload: data
    }),

    loadFromServerCourseSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_COURSE_SUCCESS,
        payload: data,
    }),

    loadFromServerCourseError: error => ({
        type: actions.LOAD_FROM_SERVER_COURSE_ERROR,
        payload: {error},
    }),

    saveIntoServerCourse: (data, actionName = 'add', onSuccessMessage, callback) => ({
        type: actions.SAVE_INTO_SERVER_COURSE,
        payload: {data, actionName, onSuccessMessage, callback},
    })

};
export default actions;
