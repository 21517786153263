import actions from './actions';

const initState = {
    isLoading: false,
};

export default function reducer(
    state = initState,
		{type, payload}
){
    switch (type) {
        case actions.LOAD_FROM_SERVER_LESSONS_PAGES:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_LESSONS_PAGES_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_LESSONS_PAGES_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_LESSONS:
            return {
                ...state,
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_LESSONS_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_LESSONS_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        case actions.LOAD_FROM_SERVER_LESSON:
            return {
                ...state,
                ...{lesson: null},
                isLoading: true
            };
        case actions.LOAD_FROM_SERVER_LESSON_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case actions.LOAD_FROM_SERVER_LESSON_ERROR:
            return {
                ...state,
                ...payload,
                isLoading: false
            };
        default:
            return state;
    }
}
