const DOCUMENT = 'TASK_';

const actions = {
    LOAD_FROM_SERVER_TASKS_PAGES: DOCUMENT + 'LOAD_FROM_SERVER_TASKS_PAGES',
    LOAD_FROM_SERVER_TASKS_PAGES_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TASKS_PAGES_SUCCESS',
    LOAD_FROM_SERVER_TASKS_PAGES_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TASKS_PAGES_ERROR',

    LOAD_FROM_SERVER_TASKS: DOCUMENT + 'LOAD_FROM_SERVER_TASKS',
    LOAD_FROM_SERVER_TASKS_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TASKS_SUCCESS',
    LOAD_FROM_SERVER_TASKS_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TASKS_ERROR',

    LOAD_FROM_SERVER_TASK: DOCUMENT + 'LOAD_FROM_SERVER_TASK',
    LOAD_FROM_SERVER_TASK_SUCCESS: DOCUMENT + 'LOAD_FROM_SERVER_TASK_SUCCESS',
    LOAD_FROM_SERVER_TASK_ERROR: DOCUMENT + 'LOAD_FROM_SERVER_TASK_ERROR',

    SAVE_INTO_SERVER_TASK: DOCUMENT + 'SAVE_INTO_SERVER_TASK',

    loadFromServerTasksPages: data => ({
        type: actions.LOAD_FROM_SERVER_TASKS_PAGES,
        payload: data
    }),

    loadFromServerTasksPagesSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TASKS_PAGES_SUCCESS,
        payload: data,
    }),

    loadFromServerTasksPagesError: error => ({
        type: actions.LOAD_FROM_SERVER_TASKS_PAGES_ERROR,
        payload: {error},
    }),

    loadFromServerTasks: data => ({
        type: actions.LOAD_FROM_SERVER_TASKS,
        payload: data
    }),

    loadFromServerTasksSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TASKS_SUCCESS,
        payload: data,
    }),

    loadFromServerTasksError: error => ({
        type: actions.LOAD_FROM_SERVER_TASKS_ERROR,
        payload: {error},
    }),

    loadFromServerTask: data => ({
        type: actions.LOAD_FROM_SERVER_TASK,
        payload: data
    }),

    loadFromServerTaskSuccess: data => ({
        type: actions.LOAD_FROM_SERVER_TASK_SUCCESS,
        payload: data,
    }),

    loadFromServerTaskError: error => ({
        type: actions.LOAD_FROM_SERVER_TASK_ERROR,
        payload: {error},
    }),

    saveIntoServerTask: (data, actionName = 'add', onSuccessMessage, callback) => ({
        type: actions.SAVE_INTO_SERVER_TASK,
        payload: {data, actionName, onSuccessMessage, callback},
    })

};
export default actions;
